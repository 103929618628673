import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navigation/Navbar";
import RafflePage from "./RafflePage";
import ContactUsPage from "./ContactUsPage";
import PropertiesPage from "./PropertiesPage";
import HomePage from "./HomePage";
import ViewAmaniDestinationPage from "./ViewAmaniDestinationPage";
import ViewBurgDestinationPage from "./ViewBurgDestinationPage";
import Loading from "../../common/components/loading/Loading";
import { useAppContext } from "../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import AffiliatesPage from "./AffiliatesPage";
import TCPage from "./TCPage";
import InfoBar from "../components/info-bar/InfoBar";
import "./MainLayout.scss";
import BlogsPage from "./BlogsPage";
import BlogsReadPage from "./BlogsReadPage";

type ILocationState = {
  from: string;
};
const Main = observer(() => {
  const { store, api } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = !store.auth.loading && store.auth.me ? true : false;

  if (store.auth.loading) return <Loading fullHeight />;

  if (isLoggedIn) {
    const state = location.state as ILocationState;
    if (state) return <Navigate to={state.from} />;
  }

  const signOut = () => {
    api.auth.logOut();
    navigate("/");
  };

  return (
    <div className="main-layout">
      <Navbar user={store.auth.me} signOut={signOut} />
      <InfoBar />
      <Outlet />
      <Footer />
    </div>
  );
});

const LoggedOutRoutes = () => {
  return (
    <Routes>
      <Route element={<Main />}>
        <Route index element={<HomePage />} />

        <Route path="properties" element={<PropertiesPage />} />
        <Route path="properties/amani" element={<ViewAmaniDestinationPage />} />
        <Route
          path="properties/burg-view"
          element={<ViewBurgDestinationPage />}
        />

        <Route path="raffle" element={<RafflePage />} />

        <Route path="news" element={<BlogsPage />} />
        <Route path="news/:id" element={<BlogsReadPage />} />

        <Route path="affiliates" element={<AffiliatesPage />} />
        <Route path="contact-us" element={<ContactUsPage />} />
        <Route path="TCs" element={<TCPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default LoggedOutRoutes;
