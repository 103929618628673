import axios from "axios";
import { stringify } from "querystring";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { IContactForm } from "../models/Contact";

export default class MailApi {
  API_URI: string;

  constructor(private api: AppApi, private store: AppStore, URI: string) {
    this.API_URI = URI;
  }

  // Send
  async sendMail(
    to: string[],
    from: string,
    cc: string[],
    subject: string,
    message: string
  ) {
    const body = {
      from: from,
      to: to.join(", "),
      cc: to.join(", "),
      subject,
      message,
    };

    const uri = `${this.API_URI}${stringify(body)}`;

    axios
      .get(uri)
      .then((response: { data: any }) => {
        //   Snackbar

        console.log("Successful request => ", response.data);
      })
      .catch((error: any) => {
        //   Snackbar
        console.log(error);
      });
  }

  async sendMailWithoutTo(subject: string, message: string) {
    const to = [""];
    const from = "";
    const cc = [""];
    await this.sendMail(to, from, cc, subject, message);
  }

  async sendContactMail(form: IContactForm) {
    const to = ["blsmit@iafrica.com.na", "rdpno5cc@gmail.com"];
    // const to = ["ananiasdave@gmail.com"];
    const from = form.email;
    const cc: string[] = [];
    const subject = form.subject;
    const message = form.message;

    await this.sendMail(to, from, cc, subject, message);
  }
}
