import React from "react";

interface IProps {
  pos?:
    | "bottom-justify"
    | "bottom-left"
    | "bottom-right"
    | "top-justify"
    | "top-left"
    | "top-right";
  mode?: "click" | "hover";
  children: React.ReactNode;
}
const Dropdown = (props: IProps) => {
  const { pos = "bottom-justify", mode = "hover", children } = props;

  return (
    <div
      className="kit-dropdown"
      data-uk-dropdown={`mode: ${mode}; pos: ${pos}`}
    >
      <ul className="kit-dropdown-nav uk-nav uk-dropdown-nav">{children}</ul>
    </div>
  );
};

export default Dropdown;
