import { faTicket, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { showModal } from "../../../common/components/modal/Modal";
import { MODAL_NAMES } from "../../../common/config/constants";
import "./RaffleQuantityModal.scss";

const RaffleQuantityModal = () => {
  const [loading, setLoading] = useState(false);
  const [qty, setQty] = useState("1");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    showModal(MODAL_NAMES.ORDER_SUMMARY_MODAL);
  };

  // const onCancel = () => {
  //   setLoading(false); // stop loading
  //   setQty("1"); // reset form
  //   hideModal(MODAL_NAMES.RAFFLE_QTY_MODAL);
  // };

  return (
    <div className="raffle-quantity-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <h3 className="title uk-modal-title">Buy Tickets</h3>

      <div className="dialog-content uk-position-relative">
        <form
          className="form uk-form-stacked uk-grid-small"
          data-uk-grid
          onSubmit={handleSubmit}
        >
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="rqm-quantity">
              Quantity
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="rqm-quantity"
                type="number"
                placeholder="Quantity"
                min={1}
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="footer uk-width-1-1">
            <div className="qty-price-container">
              <h4 className="price">N${(1500.0 * Number(qty)).toFixed(2)}</h4>
              <p className="tickets">
                {qty || 0}
                <FontAwesomeIcon
                  className="uk-margin-small-left uk-margin-small-right"
                  icon={faTimes}
                />
                Tickets
              </p>
            </div>
            <button className="kit-btn" type="submit" disabled={loading}>
              {loading ? (
                <div data-uk-spinner="ratio: .5"></div>
              ) : (
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faTicket}
                />
              )}
              Buy Tickets
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RaffleQuantityModal;
