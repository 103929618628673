import {
  IconDefinition,
  faBlog,
  faCity,
  faHome,
  faPhone,
  faShareNodes,
  faTicketAlt,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

interface INavItem {
  id: number;
  icon?: IconDefinition;
  name: string | React.ReactNode;
  path: string;
  subNavitems?: INavItem[];
}

export const CENTER_NAV_ITEMS: INavItem[] = [
  {
    id: 1,
    icon: faHome,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    icon: faCity,
    name: "Properties",
    path: "properties",
    subNavitems: [
      {
        id: 1,
        name: "Amani (First Prize)",
        path: "properties/amani",
      },
      {
        id: 2,
        name: "Burg's View (Second Prize)",
        path: "properties/burg-view",
      },
    ],
  },
  {
    id: 3,
    icon: faTicketAlt,
    name: "Raffle",
    path: "raffle",
  },
  {
    id: 4,
    icon: faShareNodes,
    name: "Affiliates",
    path: "affiliates",
  },
  {
    id: 5,
    icon: faBlog,
    name: "News",
    path: "news",
  },
  {
    id: 6,
    icon: faPhone,
    name: "Contact Us",
    path: "contact-us",
  },
];

export const RIGHT_NAV_ITEMS: INavItem[] = [
  // {
  //   id: 1,
  //   icon: faBell,
  //   name: "Notifications",
  //   path: "notifications",
  // },
];

export const PRIVATE_NAV_ITEMS: INavItem[] = [
  {
    id: 1,
    icon: faToolbox,
    name: "My Portal",
    path: "/portal",
  },
  // {
  //   id: 2,
  //   icon: faUser,
  //   name: "Profile",
  //   path: "/portal/profile",
  // },
];
