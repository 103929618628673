import { useAppContext } from "../../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import { FormEvent, useState } from "react";
import ConsortiumMembers, {
  ConsortiumMemberAccount,
} from "./ConsortiumMembers";
import {
  IConsortiumMember,
  defaultConsortiumMember,
} from "../../../common/models/ConsortiumMember";
import swal from "sweetalert";
import { IConsortium } from "../../../common/models/Consortium";
import { hideModal } from "../../../common/components/modal/Modal";
import { MODAL_NAMES } from "../../../common/config/constants";
import { faArrowLeft, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ConsortiumMembersModal.scss";

interface IConsortiumMembersModalProps {
  consortium: IConsortium;
}
const ConsortiumMembersModal = observer(
  (props: IConsortiumMembersModalProps) => {
    // Extract props
    const { consortium } = props;
    // Access app context
    const { api } = useAppContext();

    // states
    const [members, setMembers] = useState<IConsortiumMember[]>(
      consortium.members
    );
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        setLoading(true);
        if (members.length >= 1) {
          await updateConsortiumDB();
        } else {
          onAlert(
            "Error!",
            "A minimum number of 2 members is required",
            "error"
          );
        }
      } catch (error) {
        onAlert("Error!", "Failed to save consortium", "error");
      } finally {
        setLoading(false);
      }
    };

    // Save consortium to db.
    const updateConsortiumDB = async () => {
      try {
        const _consortium: IConsortium = {
          ...consortium,
          uids: members.map((m) => m.uid),
          members: members,
        };

        await api.consortium.update(_consortium);

        onReset();
      } catch (error) {
        throw error;
      }
    };

    // Cancel modal
    const onCancel = () => hideModal(MODAL_NAMES.CONSORTIUM_MEMBERS_MODAL);

    const onReset = () => {
      // Reset form
      setMembers([]);

      // Hide modal
      hideModal(MODAL_NAMES.CONSORTIUM_MEMBERS_MODAL);
    };

    // Alert messages to user
    const onAlert = (
      title: string,
      msg: string,
      icon: string = "success",
      timer: number = 2000
    ) => {
      swal({
        title: title,
        text: msg,
        icon: icon,
        timer: timer,
      });
    };

    // Search user by email address
    const findUserByEmail = async (email: string) => {
      try {
        // Check if member already added to list.
        const memberIndex = members.findIndex((m) => m.email === email);

        if (memberIndex !== -1) {
          onAlert("Error!", "Member already added to Consortium", "error");
          return [];
        }

        // Get members from db.
        const users = await api.user.getByEmail(email);
        if (users && users.length > 0) {
          const _consortiums: IConsortiumMember[] = users.map((user) => {
            return {
              ...defaultConsortiumMember,
              uid: user.uid,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
              mobilePhone: user.mobilePhone,
              email: user.email,
              photoURL: user.photoURL,
              physicalAddress: user.physicalAddress,
              country: user.country,
              passport: user.passport,
              referredBy: user.referredBy,
              createdOn: user.createdOn,
            };
          });

          return _consortiums;
        } else {
          onAlert(
            "Error!",
            "User not found on the system. Tell the user to create an account with us, at sign up page!",
            "error"
          );
        }
      } catch (error) {
        onAlert(
          "Error!",
          "Something went wrong. Contact us at +264 (81) 687 6858.",
          "error"
        );
      }
    };

    return (
      <div className="consortium-members-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button
          className="uk-modal-close-default"
          type="button"
          data-uk-close
        ></button>

        <div className="modal-header">
          <h3 className="uk-modal-title title">Consortium Members</h3>
        </div>

        <div className="dialog-content uk-position-relative">
          <form className="consortium-members" onSubmit={onSubmit}>
            <div className="tab-header">
              <p className="tab-header-description">
                A Consortium can have a Min. number of 2 members, and a Max.
                number of 5 members.
              </p>
            </div>
            <ConsortiumMembers
              members={members}
              setMembers={setMembers}
              findUserByEmail={findUserByEmail}
            >
              {/* Display the members */}
              {members.map((m) => (
                <ConsortiumMemberAccount
                  key={m.uid}
                  member={m}
                  members={members}
                  setMembers={setMembers}
                />
              ))}
            </ConsortiumMembers>

            <div className="controls">
              <button
                className="kit-btn kit-btn-muted"
                type="button"
                onClick={onCancel}
              >
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faArrowLeft}
                />
                Cancel
              </button>

              <button
                className="kit-btn kit-btn"
                type="submit"
                disabled={loading}
              >
                Save
                <FontAwesomeIcon
                  className="uk-margin-small-left"
                  icon={faFloppyDisk}
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
);

export default ConsortiumMembersModal;
