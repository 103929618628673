import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import Loading from "../../common/components/loading/Loading";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import PropertyCard from "../../common/components/property-card/PropertyCard";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import { useAppContext } from "../../common/hooks/useContext";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import PromotionBanner from "../components/promotion-banner/PromotionBanner";

const RafflePage = observer(() => {
  const { api, store } = useAppContext();
  const [loadingPromoBanner, setloadingPromoBanner] = useState(false);

  const promoBanner = store.metadata.getItemById("promo-banner");
  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  useEffect(() => {
    const load = async () => {
      setloadingPromoBanner(true);
      await api.metadata.getById("promo-banner");
      setloadingPromoBanner(false);
    };
    load();
  }, [api.metadata]);

  return (
    <div className="rafle-page">
      <CarouselSlider>
        <SliderItem
          backroundImage={sliderPath + "/1.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/2.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/3.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/4.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/5.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
      </CarouselSlider>

      <div className="uk-container uk-container-large">
        <div className="uk-margin-large">
          <SectionParagraphContent heading="Raffle">
            <Paragraph textCenter>
              Own a piece of Namibia. Enter the Raffle and stand a chance to win
              one of two prominent Namibian properties. The more raffle tickets
              you purchase, the better your chances are of winning! Enter
              Today!.
            </Paragraph>
          </SectionParagraphContent>

          <div className="uk-margin-large">
            <SectionGridContent gridClasses="uk-flex-center uk-grid-match">
              <GridCard>
                <PropertyCard
                  sticker="First Prize"
                  location="Amani Game Farm, Khomas Region"
                  title="Small Game Farm"
                  value="Cash Value N$17M"
                  path="/properties/amani"
                />
              </GridCard>

              <GridCard>
                <PropertyCard
                  sticker="Second Prize"
                  location="Burg's View, Khomas Region"
                  title="Prominent Urban Property"
                  value="Cash Value N$12M"
                  path="/properties/burg-view"
                />
              </GridCard>
            </SectionGridContent>
          </div>
        </div>
      </div>

      {!loadingPromoBanner && promoBanner ? (
        <PromotionBanner
          countries={promoBanner.asJson.countries}
          ticketsSold={promoBanner.asJson.tickets}
          closingDate={promoBanner.asJson.closingDate}
          hasContainer
        />
      ) : (
        <Loading isEllipsis />
      )}
    </div>
  );
});

export default RafflePage;
