import { ChangeEvent } from "react";
import { COUNTRIES } from "../../../common/config/constants";

interface IProps {
  value: string;
  onChange: (value: ChangeEvent<HTMLSelectElement>) => void;
}
const InputCountry = (props: IProps) => {
  const { value, onChange } = props;
  return (
    <div className="uk-margin">
      <label className="uk-form-label" htmlFor="su-country">
        Country
      </label>
      <div className="uk-form-controls">
        <select
          name="country"
          className="uk-select uk-form-small"
          id="su-country"
          value={value}
          onChange={onChange}
          required
        >
          <option value="" label="Select a country ... " disabled>
            Select a country ...
          </option>
          {COUNTRIES.map((country) => (
            <option
              value={country.value}
              label={country.text}
              key={country.value}
            >
              {country.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InputCountry;
