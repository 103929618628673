import { observer } from "mobx-react-lite";
import CheckoutForm from "../components/checkout/CheckoutForm";
import { useAppContext } from "../../common/hooks/useContext";
import Loading from "../../common/components/loading/Loading";
import { FormEvent, useEffect, useMemo, useState } from "react";
import {
  ITokenRedirect,
  defaultInitRequest,
} from "../../common/models/Paygate";
import swal from "sweetalert";
import { sortConsortium } from "../../common/utils/Sort";
import {
  IPurchaseType,
  defaultConsortium,
} from "../../common/models/Consortium";
import "./Style.scss";
import PurchaseOnHold from "../components/checkout/PurchaseOnHold";

export const defaultCheckoutForm: ICheckoutForm = {
  fullName: "",
  consortiumId: "",
  email: "",
  quantity: 1,
};
export interface ICheckoutForm {
  fullName: string;
  consortiumId: string;
  email: string;
  quantity: number;
}

const CheckoutPage = observer(() => {
  // const { api, store } = useAppContext();
  // // Get logged-in user(me)
  // const me = store.auth.me;
  // // List all consortiums.
  // const consortiums = useMemo(
  //   () => store.consortium.all.sort(sortConsortium),
  //   [store.consortium.all]
  // );

  // // Initialize checkout form
  // const [checkoutForm, setCheckoutForm] = useState<ICheckoutForm>(() => {
  //   return me
  //     ? {
  //         fullName: me.asJson.fullName,
  //         consortiumId: "",
  //         email: me.asJson.email,
  //         quantity: 1,
  //       }
  //     : { ...defaultCheckoutForm };
  // });
  // // Initialize states.
  // const [purchaseType, setPurchaseType] = useState<IPurchaseType>("individual");

  // // Initialize loading state variable.
  // const [loading, setLoading] = useState(false);

  // const onCheckout = async (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   // User not found error!
  //   if (!me) {
  //     onError();
  //     return;
  //   }

  //   // Start loading
  //   setLoading(true);

  //   try {
  //     // Get selected consortium.
  //     const foundConsortium = consortiums
  //       .map((c) => c.asJson)
  //       .find((c) => c.id === checkoutForm.consortiumId);

  //     // Find selected consortium
  //     const consortium =
  //       purchaseType === "consortium" ? foundConsortium! : defaultConsortium;

  //     // Get token from api
  //     const tokenRes = await api.checkout.intiateTransaction({
  //       ...defaultInitRequest,
  //       ...me.asJson,
  //       // update quantity
  //       quantity: checkoutForm.quantity,
  //       // consortium properies,
  //       consortiumId: consortium.id,
  //       consortiumName: consortium.name,
  //       consortiumMembers: consortium.members,
  //       consortiumMembersUids: consortium.uids,
  //       purchaseType,
  //     });
  //     // Log token response
  //     console.log("Token Response: ", tokenRes);

  //     // Initialize TokenRedirect data to be added to redirect form.
  //     const tokenRedirect: ITokenRedirect = {
  //       PAY_REQUEST_ID: tokenRes.PAY_REQUEST_ID,
  //       CHECKSUM: tokenRes.CHECKSUM,
  //     };
  //     // Log token redirect
  //     console.log("Token Redirect: ", tokenRedirect);

  //     // Submit form -> Make a post request.
  //     // Create form element
  //     const form = document.createElement("form");
  //     form.method = "POST";
  //     form.action = "https://secure.paygate.co.za/payweb3/process.trans";

  //     // Add fields to form.
  //     for (const key in tokenRedirect) {
  //       if (tokenRedirect.hasOwnProperty(key)) {
  //         const input = document.createElement("input");
  //         input.type = "hidden";
  //         input.name = key;
  //         input.value = tokenRedirect[key as keyof ITokenRedirect];
  //         form.appendChild(input);
  //       }
  //     }

  //     // Add form to dom
  //     document.body.appendChild(form);

  //     // Sumit the form
  //     form.submit();
  //   } catch (error) {
  //     onError();
  //   } finally {
  //     // Stop loading
  //     setLoading(false);
  //   }
  // };

  // // Form submit error -> Alert user about error.
  // const onError = () => {
  //   swal(
  //     "Cannot sign in into account!",
  //     "Failed to sign you in to the account! Please contact us at: +264(0) 81 687 6858.",
  //     "error"
  //   );
  // };

  // // Load consortiums from db
  // useEffect(() => {
  //   const load = async () => {
  //     setLoading(true);
  //     if (me) await api.consortium.getAll(me.asJson.uid);
  //     setLoading(false);
  //   };
  //   load();
  // }, [api.consortium, me]);

  return (
    <div className="checkout-page">
      <div className="background-image">
        <img src={process.env.PUBLIC_URL + "/assets/home/2.png"} alt="" />
      </div>

      <div className="uk-section uk-section-small">
        <div className="uk-container uk-container-large">
          <PurchaseOnHold />
          {/* {me ? (
            <CheckoutForm
              purchaseType={purchaseType}
              setPurchaseType={setPurchaseType}
              checkoutForm={checkoutForm}
              setCheckoutForm={setCheckoutForm}
              onCheckout={onCheckout}
              loading={loading}
              consortiums={consortiums}
            />
          ) : (
            <Loading isEllipsis />
          )} */}
        </div>
      </div>
    </div>
  );
});

export default CheckoutPage;
