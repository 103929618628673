import "./DocumentCard.scss";

interface IProps {
  title: string;
  docType: string;
  path: string;
}
const DocumentCard = (props: IProps) => {
  const onDownload = () => {
    window.open(props.path);

    // const a = document.createElement("a");
    // a.href = props.path;
    // a.target = "_blank";
    // a.rel = "noopener noreferrer";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  };

  return (
    <button className="document-card" onClick={onDownload}>
      <h2 className="document-card--doc-type">{props.docType}</h2>
      <h1 className="document-card--title">{props.title}</h1>
    </button>
  );
};

export default DocumentCard;
