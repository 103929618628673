import { useAppContext } from "../../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import ConsortiumTabs, { ConsortiumTabItem } from "./ConsortiumTabs";
import { useState } from "react";
import ConsortiumStep2Members, {
  ConsortiumMemberAccount,
} from "./ConsortiumStep2Members";
import ConsortiumStep1Details from "./ConsortiumStep1Details";
import "./ConsortiumModal.scss";
import {
  IConsortiumMember,
  defaultConsortiumMember,
} from "../../../common/models/ConsortiumMember";
import swal from "sweetalert";
import { IConsortium } from "../../../common/models/Consortium";
import { hideModal } from "../../../common/components/modal/Modal";
import { MODAL_NAMES } from "../../../common/config/constants";

const ConsortiumModal = observer(() => {
  const { store, api } = useAppContext();

  // const link = store.auth.affiliateLink;
  const meAsConsortiumMember = store.auth.meAsConsortiumMember;

  // states
  const [consortiumName, setConsortiumName] = useState<string>("");
  const [members, setMembers] = useState<IConsortiumMember[]>([]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [activeTab, setActiveTab] = useState<"details" | "members" | "finish">(
    "details"
  );

  // Search user by email address
  const findUserByEmail = async (email: string) => {
    try {
      // Don't add me to members list.
      if (meAsConsortiumMember && meAsConsortiumMember.email === email) {
        onAlert(
          "Account already added!",
          "Member already added to Consortium",
          "error"
        );
        return [];
      }

      // Check if member already added to list.
      const memberIndex = members.findIndex((m) => m.email === email);

      if (memberIndex !== -1) {
        onAlert(
          "Account already added!",
          "Member already added to Consortium",
          "error"
        );
        return [];
      }

      // Get members from db.
      const users = await api.user.getByEmail(email);
      if (users && users.length > 0) {
        const _consortiums: IConsortiumMember[] = users.map((user) => {
          return {
            ...defaultConsortiumMember,
            uid: user.uid,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.fullName,
            mobilePhone: user.mobilePhone,
            email: user.email,
            photoURL: user.photoURL,
            physicalAddress: user.physicalAddress,
            country: user.country,
            passport: user.passport,
            referredBy: user.referredBy,
            createdOn: user.createdOn,
          };
        });

        return _consortiums;
      } else {
        onAlert(
          "Account not found!",
          "Member account not found! Ensure member email has created an account with us!",
          "error",
          3000
        );
      }
    } catch (error) {}
  };

  // Save consortium to db.
  const onSave = async () => {
    if (!meAsConsortiumMember)
      throw new Error("Cannot create consortium, please login");

    try {
      const _members = [...members, meAsConsortiumMember];
      const _consortium: IConsortium = {
        id: "",
        name: consortiumName,
        uids: _members.map((m) => m.uid),
        members: _members,
        tickets: 0,
        createdOn: Date.now(),
      };

      await api.consortium.create(_consortium);

      onReset();
    } catch (error) {
      throw error;
    }
  };

  const onReset = () => {
    // Reset form
    setActiveTab("details");
    setConsortiumName("");
    setMembers([]);
    setAcceptTerms(false);
    // Hide modal
    hideModal(MODAL_NAMES.CONSORTIUM_MODAL);
  };

  // Alert messages to user
  const onAlert = (
    title: string,
    msg: string,
    icon: string = "success",
    timer: number = 2000
  ) => {
    swal({
      title: title,
      text: msg,
      icon: icon,
      timer: timer,
    });
  };

  return (
    <div className="consortium-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>

      <div className="modal-header">
        <h3 className="uk-modal-title title">Create Consortium</h3>
        <ConsortiumTabs>
          <ConsortiumTabItem id="details" activeTab={activeTab}>
            Details
          </ConsortiumTabItem>
          <ConsortiumTabItem id="members" activeTab={activeTab}>
            Members
          </ConsortiumTabItem>
          {/* <ConsortiumTabItem id="finish" activeTab={activeTab}>
            Finish
          </ConsortiumTabItem> */}
        </ConsortiumTabs>
      </div>

      <div className="dialog-content uk-position-relative">
        {activeTab === "details" && (
          <ConsortiumStep1Details
            setActiveTab={setActiveTab}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            consortiumName={consortiumName}
            setConsortiumName={setConsortiumName}
          />
        )}
        {activeTab === "members" && (
          <ConsortiumStep2Members
            setActiveTab={setActiveTab}
            members={members}
            setMembers={setMembers}
            findUserByEmail={findUserByEmail}
            onSave={onSave}
          >
            {meAsConsortiumMember && (
              <ConsortiumMemberAccount
                member={meAsConsortiumMember}
                members={members}
                setMembers={setMembers}
                fixed
              />
            )}

            {/* Display the members */}
            {members.map((m) => (
              <ConsortiumMemberAccount
                key={m.uid}
                member={m}
                members={members}
                setMembers={setMembers}
              />
            ))}
          </ConsortiumStep2Members>
        )}
        {/* {activeTab === "finish" && <ConsortiumStep3Finish link={link} />} */}
      </div>
    </div>
  );
});

export default ConsortiumModal;
