import {
  faBed,
  faCreditCard,
  faKiwiBird,
  faLocationDot,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import swal from "sweetalert";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../common/hooks/useContext";
import "./Style.scss";

interface IGridImageProps {
  name: string;
  ext?: "jpg" | "png" | "gif";
  caption?: string;
}
const GridImage = (props: IGridImageProps) => {
  // extract props
  const { name, ext = "jpg", caption = "" } = props;
  const galleryPath = process.env.PUBLIC_URL + "/assets/property/amani";
  const src = galleryPath + "/" + name + "." + ext;

  return (
    <a className="uk-inline" href={src} data-caption={caption}>
      <GridCard textCenter>
        <div className="gallery-image">
          <div className="gallery-image__border-bottom-left"></div>
          <div className="gallery-image__border-right-top"></div>
          <img src={src} alt={caption} />
        </div>
      </GridCard>
    </a>
  );
};

const ViewAmaniDestinationPage = observer(() => {
  const { store } = useAppContext();
  const navigate = useNavigate();

  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  const onBuy = () => {
    if (!store.auth.me)
      swal({
        title: "Sign up!",
        text: "Create an account to start buying tickets!",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else navigate("/portal/checkout");
  };

  return (
    <ErrorBoundary>
      <div className="view-destination-page">
        <CarouselSlider>
          <SliderItem
            backroundImage={sliderPath + "/1.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Small Game Farm"
          />
          <SliderItem
            backroundImage={sliderPath + "/3.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Small Game Farm"
          />
          <SliderItem
            backroundImage={sliderPath + "/5.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Small Game Farm"
          />
        </CarouselSlider>

        <div className="uk-container uk-container-large">
          <div className="uk-margin-large">
            <SectionParagraphContent
              heading={
                <Fragment>
                  <div>
                    <div className="page-heading-grid-card">
                      <h2 className="p-location">Windhoek, Khomas Region</h2>
                      <h1 className="p-title">Amani Game Windhoek (17M)</h1>
                      <h2 className="p-location uk-margin-small-top">
                        Cash Value N$17,000,000
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div className="page-heading-grid-card">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/tickets/c.png"}
                        alt="Raffle Ticket"
                      />
                    </div>
                  </div>
                </Fragment>
              }
            >
              <Paragraph textCenter>
                (Any foreigner may have full ownership)
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faLocationDot}
                />
                18 km South of Windhoek Namibia
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faMapPin}
                />
                Size 35 Ha
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faBed}
                />
                Unfurnished 9 Thatched Roof Chalets
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faBed}
                />
                2 Family Rooms 1 Lock-up & go (1 on-suite room, kitchen, launch,
                Livingroom) 1 House (3 Bedrooms) Managers suites: 7 Rooms,
                Kitchen & Lounge Workshop 7 Staff rooms 1 Borehole (not
                equipped)
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faKiwiBird}
                />
                1 Waterhole for game A lot of free roaming game. (Kudu, Zebra,
                Oryx, Leopard, Duiker, Klipspringer, Jackal, Hartebeest etc.
              </Paragraph>
            </SectionParagraphContent>

            <div className="uk-text-center uk-margin-medium" data-aos="fade-up">
              <button
                className="kit-btn kit-btn-lg kit-btn-success"
                onClick={onBuy}
              >
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faCreditCard}
                />
                Buy Now
              </button>
              {/* <button className="register-btn" onClick={onFailedPurchase}>
                Failed Purchase
              </button> */}

              <div className="uk-margin-medium uk-text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/background/PayGate-Card-Brand-Logos-PayGate.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="uk-margin-large" data-uk-lightbox="animation: slide">
            <SectionGridContent>
              <GridImage name="1" caption="Caption 1" />
              <GridImage name="2" caption="Caption 2" />
              <GridImage name="3" caption="Caption 3" />
              <GridImage name="4" caption="Caption 4" />
              <GridImage name="5" caption="Caption 5" />
              <GridImage name="6" ext="png" caption="Caption 6" />
              <GridImage name="7" caption="Caption 7" />
              <GridImage name="8" caption="Caption 8" />
              <GridImage name="9" caption="Caption 9" />
              <GridImage name="10" caption="Caption 10" />
              <GridImage name="11" caption="Caption 11" />
              <GridImage name="12" caption="Caption 12" />
              <GridImage name="13" caption="Caption 13" />
              <GridImage name="14" caption="Caption 14" />
              <GridImage name="15" caption="Caption 15" />
              <GridImage name="16" ext="png" caption="Caption 16" />
            </SectionGridContent>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default ViewAmaniDestinationPage;
