import { IPurchaseType } from "./Consortium";
import { IConsortiumMember } from "./ConsortiumMember";
import Model from "./Model";

export const defaultTicket: ITicket = {
  id: "",
  transactionId: "",
  // User properties
  uid: "",
  userName: "",
  referredBy: "",
  country: "",
  // Consortium properties
  consortiumId: "",
  consortiumName: "",
  consortiumMembers: [],
  consortiumMembersUids: [],
  purchaseType: "individual",
  // other properties
  description: "",
  price: 0,
  referredByCommissionRate: 5,
  isPaid: false,
  isCancelled: false,
  isVerified: false,
  isValid: true,
  createdOn: 0
};


export interface ITicket {
  id: string;
  transactionId: string;
  // User properties
  uid: string;
  userName: string;
  referredBy: string;
  country: string;
  // Consortium properties
  consortiumId: string;
  consortiumName: string;
  consortiumMembers: IConsortiumMember[];
  consortiumMembersUids: string[];
  purchaseType: IPurchaseType;
  // Other properties
  description: string;
  price: number;
  referredByCommissionRate: number;
  isPaid: boolean;
  isCancelled: boolean;
  isVerified: boolean;
  isValid: boolean;
  createdOn: number;
}


export default class Ticket extends Model<ITicket> {
  view() {
    console.log("View");
  }

  update() {
    // showModal(MODAL_NAMES.REGISTER_MODAL);
    this.store.ticket.select(this.item);
  }

  edit() {
    // showModal(MODAL_NAMES.REGISTER_MODAL);
    this.store.ticket.select(this.item);
  }

  async remove() {
    if (!window.confirm("Delete the ticket?")) return;
    await this.api.ticket.delete(this.item);
  }
}
