import React, { ReactNode } from "react";
import "./NoData.scss";

interface IProps {
  children: ReactNode;
}
const NoData = (props: IProps) => {
  const { children } = props;

  return (
    <div className="no-data uk-padding uk-text-center">
      <img
        src={process.env.PUBLIC_URL + "/icons/empty-box-shade.png"}
        alt="Empty"
        height={200}
        width={200}
      />
      <h6 className="no-data-message ">{children}</h6>
    </div>
  );
};

export default NoData;
