import Model from "./Model";

export const defaultConsortiumMember: IConsortiumMember = {
  uid: "",
  firstName: "",
  lastName: "",
  fullName: "",
  mobilePhone: "",
  email: "",
  photoURL: null,
  physicalAddress: "",
  country: "",
  passport: "",
  referredBy: "",
  createdOn: 0
};

export interface IConsortiumMember {
  uid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  mobilePhone: string;
  email: string;
  photoURL: string | null;
  physicalAddress: string;
  country: string;
  passport: string;
  referredBy: string;
  createdOn: number;
}

export default class ConsortiumMember extends Model<IConsortiumMember>  { }
