import {
  faCircleInfo,
  faExclamationTriangle,
  faFaceLaughWink,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorBoundary from "../../../common/components/error-boundary/ErrorBoundary";
import { useAppContext } from "../../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import "./InfoBar.scss";

const InfoBar = observer(() => {
  const { store, api } = useAppContext();
  const [isClosed, setIsClosed] = useState(false);

  // Information bar
  const INFORMATION_BAR_ID = "information-bar";

  // Get info-bar
  const informationBar = store.metadata.getItemById(INFORMATION_BAR_ID);

  const infoType = informationBar && informationBar.asJson.type;

  // Class name
  const className =
    informationBar && informationBar.asJson.disabled
      ? `info-bar info-bar--${infoType} info-bar--disabled uk-container uk-container-large`
      : `info-bar info-bar--${infoType} uk-container uk-container-large`;

  const onClose = () => {
    setIsClosed(true);
  };

  // Load the information bar
  useEffect(() => {
    const load = () => api.metadata.getById(INFORMATION_BAR_ID);
    load();
  }, [api.metadata]);

  // No information bar
  if (
    !informationBar ||
    (informationBar && informationBar.asJson.disabled) ||
    isClosed
  )
    return <></>;

  // Found information bar
  return (
    <ErrorBoundary>
      <div className="info-bar--border">
        <div className={className} data-aos="fade-up">
          <div className="type">
            {infoType === "info" && (
              <FontAwesomeIcon className="icon" icon={faCircleInfo} />
            )}
            {infoType === "danger" && (
              <FontAwesomeIcon className="icon" icon={faExclamationTriangle} />
            )}
            {infoType === "success" && (
              <FontAwesomeIcon className="icon" icon={faFaceLaughWink} />
            )}
          </div>
          <p className="text">{informationBar.asJson.message}</p>
          {informationBar.asJson.url && (
            <a
              className="read-more-btn"
              // target="_blank"
              // rel="noopener noreferrer"
              href={informationBar.asJson.url}
            >
              Learn More
            </a>
          )}

          <button className="close-btn" onClick={onClose}>
            <FontAwesomeIcon className="icon" icon={faTimes} />
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default InfoBar;
