import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useState } from "react";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import swal from "sweetalert";
import "./Style.scss";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import PropertyCard from "../../common/components/property-card/PropertyCard";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import { useAppContext } from "../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import { IContactForm } from "../../common/models/Contact";
import AdvertBanner from "../components/advertising-banner/AdvertBanner";

const defaultContactForm: IContactForm = {
  firstName: "",
  lastName: "",
  email: "",
  contactNo: "",
  subject: "",
  message: "",
};

const ContactUsPage = observer(() => {
  const { api } = useAppContext();

  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  const [contact, setContact] = useState<IContactForm>({
    ...defaultContactForm,
  });
  const [loading, setLoading] = useState(false);

  const onSend = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const _contact: IContactForm = {
        ...contact,
        message: contact.message.replace("\n", "<br/>"),
      };
      api.mail.sendContactMail(_contact);
      onSuccess();
    } catch (error) {
      onError();
    }
    setLoading(true); // start loading
  };

  const onError = () => {
    swal(
      "Message Failed!",
      "Failed to send your message! Please contact us at: +264(0) 81 129 4067.",
      "error"
    );
    onCancel();
  };

  const onSuccess = (records?: any) => {
    swal("Message Send Successful!", "We'll contact you shortly!", "success");
    onCancel();
  };

  const onCancel = () => {
    setLoading(false); // stop loading
    setContact({ ...defaultContactForm });
  };

  return (
    <div className="contact-us-page">
      <CarouselSlider>
        <SliderItem
          backroundImage={sliderPath + "/1.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/2.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/3.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/4.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
        <SliderItem
          backroundImage={sliderPath + "/5.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Raffle"
        />
      </CarouselSlider>

      <div className="uk-container uk-container-large">
        <div className="uk-margin-large">
          <SectionParagraphContent heading="Contact Us">
            <Paragraph textCenter>
              Own a piece of Namibia. Enter the Raffle and stand a chance to win
              one of two prominent Namibian properties. The more raffle tickets
              you purchase, the better your chances are of winning! Enter
              Today!.
            </Paragraph>
          </SectionParagraphContent>
        </div>

        <div
          className="contact-form uk-margin-large uk-card uk-card-default uk-card-body"
          data-aos="fade-up"
        >
          <form
            className="uk-form-stacked uk-grid uk-child-width-1-2@s"
            data-uk-grid
            onSubmit={onSend}
          >
            <div>
              <div>
                <label className="uk-form-label" htmlFor="contact-firstname">
                  First Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="contact-firstname"
                    type="text"
                    value={contact.firstName}
                    onChange={(e) =>
                      setContact({ ...contact, firstName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <label className="uk-form-label" htmlFor="contact-lastname">
                  Last Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="contact-lastname"
                    type="text"
                    value={contact.lastName}
                    onChange={(e) =>
                      setContact({ ...contact, lastName: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <label className="uk-form-label" htmlFor="contact-email">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="contact-email"
                    type="email"
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <label className="uk-form-label" htmlFor="contact-contact-no.">
                  Contact Number
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="contact-contact-no."
                    type="tel"
                    value={contact.contactNo}
                    onChange={(e) =>
                      setContact({ ...contact, contactNo: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div>
                <label className="uk-form-label" htmlFor="contact-subject">
                  Subject
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="contact-subject"
                    type="text"
                    value={contact.subject}
                    onChange={(e) =>
                      setContact({ ...contact, subject: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="uk-width-1-1">
              <div>
                <label className="uk-form-label" htmlFor="contact-message">
                  Message
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea"
                    id="contact-message"
                    rows={5}
                    value={contact.message}
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        message: e.target.value,
                      })
                    }
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 uk-text-center">
              <button
                className="kit-btn kit-btn-lg kit-btn-primary"
                type="submit"
                disabled={loading}
              >
                Send Message{" "}
                {loading ? (
                  <div data-uk-spinner="ratio: .5"></div>
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} />
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="uk-margin-large">
          <SectionGridContent
            heading="Properties"
            gridClasses="uk-flex-center uk-grid-match"
          >
            <GridCard>
              <PropertyCard
                location="Amani Game Farm, Khomas Region"
                title="Small Game Farm"
                value="Cash Value N$17M"
                path="/properties/amani"
              />
            </GridCard>

            <GridCard>
              <PropertyCard
                location="Burg's View, Khomas Region"
                title="Prominent Urban Property"
                value="Cash Value N$12M"
                path="/properties/burg-view"
              />
            </GridCard>
          </SectionGridContent>
        </div>

        <div className="uk-margin-large">
          <AdvertBanner />
        </div>
      </div>
    </div>
  );
});

export default ContactUsPage;
