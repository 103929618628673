const backgroundColor = "#F2F2F2";

// Fonts
const h1FontSize = 14;

export const header = (title: string) => {
  const docDef = {
    text: title,
    style: "subheader",
    fontSize: h1FontSize,
  };
  return docDef;
};

export const sectionHeader = (title: string, content?: string) => {
  const docDef = {
    style: "sectionHeader",
    table: {
      widths: ["*", "auto"],
      body: [
        [
          {
            text: title,
            margin: [5, 0],
            padding: [0, 0],
          },
          {
            text: content ? content : "",
            noWrap: true,
            margin: [0, 0, 5, 0],
            padding: [0, 0],
          },
        ],
      ],
    },
    layout: "noBorders",
  };
  return docDef;
};

export const bodyText = (
  content: string,
  style: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body" = "body",
  align?: "left" | "center"
) => {
  const docDef = {
    text: content,
    style: align ? [style, align] : style,
  };
  return docDef;
};

export const marginTopBottom = (value: number = 20) => {
  const docRef = {
    text: "",
    margin: [0, value],
  };
  return docRef;
};

export const grid = (body: Array<Array<any>>) => {
  const widths = [];
  const columns = body[0].length;

  for (let index = 0; index < columns; index++) {
    widths[index] = "*";
  }

  const gridRef = {
    style: "gridStyle",
    table: {
      widths: widths,
      body: body,
    },
    layout: "noBorders",
  };

  return gridRef;
};

interface ITable {
  body: Array<Array<any>>;
  widths?: string[];
  headerRows?: number;
}
export const table = (props: ITable) => {
  const { body, widths, headerRows } = props;

  if (widths && widths.length !== body[0].length) {
    const widths = [];
    const columns = body[0].length;
    for (let index = 0; index < columns; index++) {
      widths[index] = "*";
    }
  }

  const gridRef = {
    style: "tableStyle",
    table: {
      headerRows: headerRows,
      widths: widths,
      body: body,
    },
    layout: "lightHorizontalLines",
  };

  return gridRef;
};

const getBase64ImageFromURL = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};

interface IImage {
  imagePath?: string;
  dimension?: [number, number];
  style?: "image" | "imagePosLeft";
}
export const image = async (imageProps: IImage = {}) => {
  const {
    imagePath = "logo.png",
    dimension = [220, 220],
    style = "image",
  } = imageProps;

  return {
    style: style,
    image: await getBase64ImageFromURL(
      `${process.env.PUBLIC_URL}/${imagePath}`
    ),
    fit: dimension,
  };
};

const styles = {
  // Text styles,
  center: {
    alignment: "center",
  },
  h1: {
    fontSize: 18,
    bold: true,
    margin: [0, 5, 0, 0],
  },
  h2: {
    fontSize: 16,
    bold: true,
    margin: [0, 5, 0, 0],
  },
  h3: {
    fontSize: 12,
    bold: true,
    margin: [0, 5, 0, 0],
  },
  h4: {
    fontSize: 10,
    bold: true,
    margin: [0, 5, 0, 0],
  },
  h6: {
    fontSize: 8,
    bold: true,
    margin: [0, 5, 0, 0],
  },
  body: {
    fontSize: 10,
    margin: [0, 5, 0, 0],
  },
  image: {
    alignment: "center",
    margin: [0, 0, 0, 10],
  },
  imagePosLeft: {
    alignment: "left",
    margin: [0, 0, 0, 10],
  },
  sectionHeader: {
    fontSize: 14,
    margin: [0, 5, 0, 10],
    border: [false, false, false, false],
    bold: true,
    fillColor: backgroundColor,
  },
  gridStyle: {
    table: {
      body: {
        border: "none",
      },
    },
  },
};

export const TicketDocDefinition = async (ticketRef: any) => {
  return {
    // layout
    pageSize: {
      width: 250,
      height: "auto",
    },
    pageMargins: [25, 40, 25, 40], // [left, top, right, bottom]
    content: [
      // Logo
      await image(),
      ...ticketRef,
    ],

    styles: styles,
    defaultStyle: {},
  };
};

export const InvoiceDocDefinition = async (invoiceRef: any) => {
  return {
    // layout
    pageSize: {
      width: 450,
      height: "auto",
    },
    pageMargins: [20, 20, 20, 20], // [left, top, right, bottom]
    content: [...invoiceRef],

    styles: styles,
    defaultStyle: {},
  };
};
