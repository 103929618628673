// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY || "AIzaSyA12jPgJGtZyg9Ycj7XbGWlejwysT-rs_A",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN || "raffle-dev-5dde9.firebaseapp.com",
  projectId: process.env.REACT_APP_PROJECT_ID || "raffle-dev-5dde9",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET || "raffle-dev-5dde9.appspot.com",
  messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID || "211856023986",
  appId: process.env.REACT_APP_APP_ID || "1:211856023986:web:f6ca8901359092f41ec4ab",
  measurementId: process.env.REACT_APP_MEASUREMENT_ID || "G-3HP1VYGFXB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "app");
const appAuthWorker = initializeApp(firebaseConfig, "appAuthWorker");

export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const authWorker = getAuth(appAuthWorker);
export const storage = getStorage(app);
export const functions = getFunctions(app);
