import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { NavLink } from "react-router-dom";
import { MODAL_NAMES } from "../../../common/config/constants";
import { hideModal } from "../../../common/components/modal/Modal";

interface IConsortiumStep1DetailsProps {
  setActiveTab: Dispatch<SetStateAction<"details" | "members" | "finish">>;
  consortiumName: string;
  setConsortiumName: Dispatch<SetStateAction<string>>;
  acceptTerms: boolean;
  setAcceptTerms: Dispatch<SetStateAction<boolean>>;
}
const ConsortiumStep1Details = (props: IConsortiumStep1DetailsProps) => {
  // extract props
  const {
    setActiveTab,
    consortiumName,
    setConsortiumName,
    acceptTerms,
    setAcceptTerms,
  } = props;

  // initialize states
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setActiveTab("members");
  };

  const onCancel = () => hideModal(MODAL_NAMES.CONSORTIUM_MODAL);

  return (
    <form className="consortium-details" onSubmit={onSubmit}>
      <div className="tab-header">
        <h5 className="tab-header-title">Update Consortium Details</h5>
        <p className="tab-header-description">
          Choose a creative name for your Consortium, this is the name that used
          for purchasing.
        </p>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="cstium-name">
          Name of consortium
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input"
            id="cstium-name"
            type="text"
            value={consortiumName}
            onChange={(e) => setConsortiumName(e.target.value)}
            required
          />
        </div>
      </div>

      <div className="uk-margin">
        <label className="uk-form-label" htmlFor="su-terms-and-conditions">
          <input
            className="uk-checkbox uk-margin-small-right"
            id="su-terms-and-conditions"
            type="checkbox"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
            required
          />
          Accept Terms & Conditions. <NavLink to="/TCs">Read more</NavLink>
        </label>
      </div>

      <div className="controls">
        <button
          className="kit-btn kit-btn-muted"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </button>

        <button className="kit-btn kit-btn" type="submit" disabled={loading}>
          Next step
          <FontAwesomeIcon
            className="uk-margin-small-left"
            icon={faArrowRight}
          />
        </button>
      </div>
    </form>
  );
};

export default ConsortiumStep1Details;
