import React from "react";
import PropertyCard from "../../common/components/property-card/PropertyCard";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";

const PropertiesPage = () => {
  return (
    <div className="properties-page">
      <div className="uk-section uk-section-small">
        <div className="uk-container uk-container-large">
          <div className="uk-margin-large">
            <SectionGridContent gridClasses="uk-grid-match">
              <GridCard>
                <PropertyCard
                  sticker="First Prize"
                  location="Amani Game Farm, Khomas Region"
                  title="Small Game Farm"
                  value="Cash Value N$17M"
                  path="/properties/amani"
                />
              </GridCard>

              <GridCard>
                <PropertyCard
                  sticker="Second Prize"
                  location="Burg's View, Khomas Region"
                  title="Prominent Urban Property"
                  value="Cash Value N$12M"
                  path="/properties/burg-view"
                />
              </GridCard>
            </SectionGridContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertiesPage;
