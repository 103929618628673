import { observer } from "mobx-react-lite";
import { useAppContext } from "../../common/hooks/useContext";
import Loading from "../../common/components/loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRotate } from "@fortawesome/free-solid-svg-icons";
import { ITransaction } from "../../common/models/Transaction";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import { round } from "../../common/utils/Math";
import "./Style.scss";

interface ITransactionProps {
  transaction: ITransaction;
}
const PaidTransaction = (props: ITransactionProps) => {
  const { transaction } = props;
  const { fullName, createdOn, quantity } = transaction;
  const date = new Date(createdOn).toDateString();

  const navigate = useNavigate();

  const onView = () =>
    transaction.purchaseType === "individual"
      ? navigate("/portal/raffle")
      : navigate(`/portal/consortium/${transaction.consortiumId}`);

  return (
    <div className="raffle-card kit-card kit-card-lg kit-card-default">
      <div className="info-icon">
        <img src={process.env.PUBLIC_URL + "/icons/3223311.png"} alt="" />
      </div>
      <h1 className="title">Download Tickets (x{quantity})</h1>

      <div className="raffle-card__body">
        <p className="raffle-card-label">Full name</p>
        <p className="raffle-full-name raffle-card-value">{fullName}</p>

        <p className="raffle-card-label">Date of Purchase</p>
        <p className="raffle-dop raffle-card-value">{date}</p>

        <p className="raffle-card-label">Tickets</p>
        <p className="raffle-code raffle-card-value">x{quantity}</p>
      </div>

      <div className="raffle-card__footer">
        <button className="kit-btn kit-btn-lg uk-width-1-1" onClick={onView}>
          <FontAwesomeIcon className="uk-margin-small-right" icon={faEye} />
          View
        </button>
      </div>
    </div>
  );
};

const UnpaidTransaction = (props: ITransactionProps) => {
  const { transaction } = props;
  const { fullName, amount, countryCode, resultDesc, quantity } = transaction;
  const amountDollars = round(Number(amount) / 100);

  const navigate = useNavigate();

  const onRetry = () => {
    navigate(`/portal/checkout/${quantity}`);
  };

  return (
    <div className="raffle-card kit-card kit-card-lg kit-card-default">
      <div className="info-icon">
        <img src={process.env.PUBLIC_URL + "/icons/3687129.png"} alt="" />
      </div>

      <h1 className="title">Failed Purchase</h1>

      <div className="raffle-card__body">
        <p className="raffle-card-label">Full name</p>
        <p className="raffle-full-name raffle-card-value">{fullName || "-"}</p>

        <p className="raffle-card-label">Quantity</p>
        <p className="raffle-code raffle-card-value">{quantity || "-"}</p>

        <p className="raffle-card-label">Amount (N$)</p>
        <p className="raffle-code raffle-card-value">{amountDollars || "-"}</p>

        <p className="raffle-card-label">Country</p>
        <p className="raffle-dop raffle-card-value">{countryCode || "-"}</p>

        <p className="raffle-card-label">Error Message</p>
        <p className="raffle-card-error-msg raffle-card-value">
          {resultDesc || "-"}
        </p>
      </div>

      <div className="raffle-card__footer">
        <button className="kit-btn kit-btn-lg kit-btn-danger" onClick={onRetry}>
          <FontAwesomeIcon className="uk-margin-small-right" icon={faRotate} />
          Retry Checkout
        </button>
      </div>
    </div>
  );
};

const TransactionPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { api, store } = useAppContext();

  const [loading, setLoading] = useState(false);
  const transaction = id ? store.transaction.getItemById(id) : null;

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (id) await api.transaction.getById(id);
      setLoading(false);
    };
    load();
  }, [api.transaction, id]);

  if (loading) return <Loading isEllipsis fullHeight />;

  return (
    <div className="transaction-page">
      <div className="background-image">
        <img src={process.env.PUBLIC_URL + "/assets/home/2.png"} alt="" />
      </div>

      <div className="uk-section uk-section-small">
        <div className="uk-container uk-container-large">
          {transaction && (
            <ErrorBoundary>
              {transaction.asJson.isPaid ? (
                <PaidTransaction transaction={transaction.asJson} />
              ) : (
                <UnpaidTransaction transaction={transaction.asJson} />
              )}
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
});

export default TransactionPage;
