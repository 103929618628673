import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {
  InvoiceDocDefinition,
  bodyText,
  image,
  grid,
  marginTopBottom,
  table,
} from "./DocDefition";
import { IWithdrawal } from "../models/Withdrawal";
import { round } from "../utils/Math";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// create pdf
const createPDF = async (docRef: any, filename: string) => {
  pdfMake
    .createPdf((await InvoiceDocDefinition(docRef)) as any)
    .download(filename)
};


const invoiceRef = async (withdraw: IWithdrawal) => {
  const { id, userName, bankName, bankAccountType, bankAddress, bankSwiftCode, bankAccountNumber, amount, createdOn, status } = withdraw;

  const date = new Date(createdOn).toDateString()
  const amountDollars = round(Number(amount) / 100)

  // Logo
  const logo = await image({ dimension: [150, 150], style: "imagePosLeft" });

  // const invoiceHeader = grid([["Image", "Text"]], 3)
  const invoiceHeader = grid([
    [
      logo,
      [
        bodyText("NAMIBIA PROPERTY RAFFLE", "h4"),
        bodyText(`INVOICE NO: #${id}`, "h4"),
        bodyText(`WITHDRAW DATE: ${date}`, "h4"),
        bodyText(`STATUS: ${status.toUpperCase()}`, "h4"),
      ]
    ]
  ])

  // Billing address and payment information
  const billingAddress = grid([
    [
      [
        bodyText("BILL TO:", "h4"),
        bodyText("Basil Smit Property Raffle CC."),
        bodyText("Windhoek"),
        bodyText("Namibia"),
      ],
      [
        bodyText("PAYMENT TO:", "h4"),
        bodyText(`Full Name: ${userName}`),
        bodyText(`Bank Name: ${bankName}`),
        bodyText(`Bank Type: ${bankAccountType}`),
        bodyText(`Bank Address: ${bankAddress}`),
        bodyText(`SWIFT, BIC or Branch code: ${bankSwiftCode}`),
        bodyText(`Bank Account No: ${bankAccountNumber}`),
      ],
    ]
  ])

  // Items summary table.
  const itemsSummaryTable = table(
    {
      body: [
        [
          bodyText("ITEM DESCRIPTION", "h4"),
          bodyText("QTY", "h4"),
          bodyText("AMOUNT", "h4"),
        ],
        [
          bodyText("Commission earned via affiliates."),
          bodyText("-"),
          bodyText(`N$${amountDollars}`)
        ],
        [
          "",
          bodyText("Total Amount:", "h4"),
          bodyText(`N$${amountDollars}`, "h4")
        ]
      ],
      widths: ["*", "20%", "20%"],
      headerRows: 1
    }
  )

  // Footer: social links
  const socialLinks = await image({ imagePath: "icons/social-links.jpg", dimension: [100, 100] });

  const ticketRef = [
    marginTopBottom(10),
    invoiceHeader,
    marginTopBottom(10),
    billingAddress,
    marginTopBottom(10),
    itemsSummaryTable,
    marginTopBottom(20),
    bodyText("THANKS FOR YOUR BUSINESS!", "h1", "center"),
    bodyText("www.namibiapropertyraffle.com", "h4", "center"),
    socialLinks,
    marginTopBottom(20),

  ]

  return ticketRef
};

const InvoicePDFGen = async (withdraw: IWithdrawal) => {
  // Return pdf->docRef of invoice
  const docRef = await invoiceRef(withdraw);
  const filename = `NamPropRaffle-${withdraw.id}.pdf`
  // create pdf
  createPDF(docRef, filename);
};

export default InvoicePDFGen;
