import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import {
  faCity,
  faHome,
  faPeopleGroup,
  faShareNodes,
  faTicketAlt,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

interface INavItem {
  id: number;
  icon?: IconDefinition;
  name: string | React.ReactNode;
  path: string;
  subNavitems?: INavItem[];
}

export const CENTER_NAV_ITEMS: INavItem[] = [
  {
    id: 1,
    icon: faHome,
    name: "Home",
    path: "/portal",
  },
  {
    id: 2,
    icon: faCity,
    name: "Properties",
    path: "properties",
  },
  {
    id: 3,
    icon: faTicketAlt,
    name: "Raffle",
    path: "raffle",
  },
  {
    id: 4,
    icon: faShareNodes,
    name: "Affiliates",
    path: "affiliates",
  },
  {
    id: 5,
    icon: faPeopleGroup,
    name: "Consortium",
    path: "consortium",
  },
];

export const RIGHT_NAV_ITEMS: INavItem[] = [
]

export const PRIVATE_NAV_ITEMS: INavItem[] = [
  {
    id: 1,
    icon: faToolbox,
    name: "Back to Website",
    path: "/",
  },
];
