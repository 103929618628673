import swal from "sweetalert";
import { useAppContext } from "../../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import { copyTextToClipboard } from "../../../common/utils/CopyToClipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AFFILIATE_POST } from "../../../common/config/constants";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./AffiliateModal.scss";

const AffiliateModal = observer(() => {
  const { store } = useAppContext();

  const link = store.auth.affiliateLink;
  const { title, summary, hashTags } = AFFILIATE_POST;
  const txtHashTags = hashTags.join(",");

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
  const whatsappLink = `whatsapp://send?text=${title}.%0a${link}`;
  const twitterLink = `http://twitter.com/share?text=${title}&url=${link}&hashtags=${txtHashTags}`;
  const linkinLink = `https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${title}&summary=${summary}`;

  const onCopy = async () => {
    if (link) copyTextToClipboard(link, onSuccess, onError);
    else onError();
  };

  const onSuccess = () => {
    swal({
      title: "Copied!",
      text: "The link was copied successfully!",
      icon: "success",
      timer: 1200,
    });
  };

  const onError = () => {
    swal({
      title: "Failed to copy!",
      text: "The link failed to copy. Please highlight and copy it manually.",
      icon: "error",
      timer: 3000,
    });
  };

  // const onCancel = () => hideModal(MODAL_NAMES.AFFILIATE_MODAL);

  return (
    <div className="affiliate-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>

      <div className="modal-header">
        <div className="left">
          <h3 className="uk-modal-title title">Refer a friend</h3>
          <p className="description">
            You wish to share the property raffle with friends. Invite them to
            the website and gain points
          </p>
        </div>
        <div className="right">
          <img
            src="https://cdn-icons-png.flaticon.com/512/8790/8790365.png"
            alt=""
          />
        </div>
      </div>

      <div className="dialog-content uk-position-relative">
        <div className="affiliate-link-container uk-margin">
          <p className="link">{link}</p>
          <button className="copy-btn kit-btn kit-btn-sm" onClick={onCopy}>
            <FontAwesomeIcon icon={faCopy} /> Copy
          </button>
        </div>

        <div className="share-on-socials">
          <h4 className="share-title">Share it: </h4>
          <p className="share-description">
            Share your affiliate link to accummulate points when people use your
            link.
          </p>

          <div className="social-links">
            <div className="social-link-item">
              <a
                className="facebook-link"
                href={facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="icon" icon={faFacebook} /> Share on
                Facebook
              </a>
            </div>
            <div className="social-link-item">
              <a
                className="twitter-link"
                href={twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="icon" icon={faTwitter} /> Share on
                Twitter
              </a>
            </div>
            <div className="social-link-item">
              <a
                className="linkedin-link"
                href={linkinLink}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="icon" icon={faLinkedin} /> Share on
                LinkedIn
              </a>
            </div>
            <div className="social-link-item">
              <a
                className="whatsapp-link"
                href={whatsappLink}
                data-action="share/whatsapp/share"
              >
                <FontAwesomeIcon className="icon" icon={faWhatsapp} /> Share via
                Whatsapp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AffiliateModal;
