import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import "./OrderSummaryModal.scss";

const OrderSummaryModal = () => {
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [region, setRegion] = useState("");
  const qty = 2;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  // const onCancel = () => {
  //   setLoading(false); // stop loading
  //   setFullName(""); // reset form
  //   setEmail(""); // reset form
  //   setRegion("");
  //   hideModal(MODAL_NAMES.ORDER_SUMMARY_MODAL);
  // };

  return (
    <div className="order-summary-modal uk-modal-dialog uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>

      <form className="form" onSubmit={handleSubmit}>
        <div className="uk-modal-body">
          <h3 className="title uk-modal-title">Order Summary</h3>
          <div
            className="dialog-content uk-position-relative uk-form-stacked uk-grid-small"
            data-uk-grid
          >
            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="osm-fullname">
                Full name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  id="osm-fullname"
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="osm-email">
                Email Address
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  id="osm-email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="osm-region">
                Region
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input"
                  id="osm-region"
                  type="text"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="footer uk-modal-body">
          <div className="space-between qty-price-container">
            <p className="tickets">{qty || 0} Tickets</p>
            <h4 className="price">
              <span className="label">Subtotal</span>
              <span className="value">
                N$
                {(1500.0 * Number(qty)).toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="space-between qty-price-container">
            <p></p>
            <h4 className="price">
              <span className="label">Total</span>
              <span className="value">
                N$
                {(1500.0 * Number(qty)).toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="space-between">
            <div></div>
            <button className="kit-btn" type="submit" disabled={loading}>
              {loading ? (
                <div data-uk-spinner="ratio: .5"></div>
              ) : (
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faCreditCard}
                />
              )}
              Continue Checkout
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderSummaryModal;
