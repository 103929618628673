import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Loading from "../../common/components/loading/Loading";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import { useAppContext } from "../../common/hooks/useContext";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import PromotionBanner from "../components/promotion-banner/PromotionBanner";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AdvertBanner from "../components/advertising-banner/AdvertBanner";

const HomePage = observer(() => {
  const { api, store } = useAppContext();

  const [loadingPromoBanner, setloadingPromoBanner] = useState(false);
  const navigate = useNavigate();

  const promoBanner = store.metadata.getItemById("promo-banner");
  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  const onBuy = () => {
    if (!store.auth.me)
      swal({
        title: "Sign up!",
        text: "Create an account to start buying tickets!",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else navigate("/portal/checkout");
  };

  useEffect(() => {
    const load = async () => {
      setloadingPromoBanner(true);
      await api.metadata.getById("promo-banner");
      setloadingPromoBanner(false);
    };
    load();
  }, [api.metadata]);

  return (
    <div className="home-page">
      <CarouselSlider>
        <SliderItem
          backroundImage={sliderPath + "/1.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Own a piece of Namibia"
        />
        <SliderItem
          backroundImage={sliderPath + "/2.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Win a piece of Namibia"
        />
        <SliderItem
          backroundImage={sliderPath + "/3.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Win a small Game Farm"
        />
        <SliderItem
          backroundImage={sliderPath + "/4.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Win a prominent urban property"
        />
        <SliderItem
          backroundImage={sliderPath + "/5.jpg"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Own a piece of Namibia"
        />
      </CarouselSlider>

      <div className="uk-container uk-container-large">
        <div className="uk-margin-medium">
          <SectionParagraphContent heading="Enter to Win!">
            <Paragraph textCenter>
              Own a piece of Namibia. Enter the Raffle and stand a chance to win
              one of two prominent Namibian properties. The more raffle tickets
              you purchase, the better your chances are of winning! Enter
              Today!.
            </Paragraph>
          </SectionParagraphContent>
        </div>

        <div className="uk-text-center uk-margin-medium" data-aos="fade-up">
          <button
            className="kit-btn kit-btn-lg kit-btn-success"
            onClick={onBuy}
          >
            <FontAwesomeIcon
              className="uk-margin-small-right"
              icon={faCreditCard}
            />
            Buy Now
          </button>
        </div>

        <div className="uk-margin-medium uk-text-center" data-aos="fade-up">
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/background/PayGate-Card-Brand-Logos-PayGate.png"
            }
            alt=""
          />
        </div>

        <div
          className="standalone-ticket uk-text-center uk-margin-medium"
          data-aos="fade-up"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/tickets/c.png"}
            alt="Raffle Ticket"
          />
        </div>
      </div>

      <div className="uk-margin-large">
        <AdvertBanner />
      </div>

      {!loadingPromoBanner && promoBanner ? (
        <PromotionBanner
          backgroundImg={process.env.PUBLIC_URL + "/assets/home/1.png"}
          countries={promoBanner.asJson.countries}
          ticketsSold={promoBanner.asJson.tickets}
          closingDate={promoBanner.asJson.closingDate}
          hasContainer
        />
      ) : (
        <Loading isEllipsis />
      )}
    </div>
  );
});

export default HomePage;
