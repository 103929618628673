import {
  query,
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { db } from "../config/firebase-config";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { IConsortium } from "../models/Consortium";
import { where } from "firebase/firestore";

export default class ConsortiumApi {
  private path: string = "consortium";

  constructor(private api: AppApi, private store: AppStore) { }

  // path getters and setters
  setPath(path: string) {
    this.path = path;
  }

  async getAll(uid: string) {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.consortium.removeAll();

    // create the query
    const $query = query(collection(db, path), where("uids", "array-contains", uid)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IConsortium[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ id: doc.id, ...doc.data() } as IConsortium);
          });

          this.store.consortium.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    return await new Promise<Unsubscribe>((resolve, reject) => {

      const unsubscribe = onSnapshot(
        doc(db, path, id),
        (doc) => {
          if (!doc.exists) {
            resolve(unsubscribe)
            return;;
          };

          const item = { id: doc.id, ...doc.data() } as IConsortium;

          this.store.consortium.load([item]);
          resolve(unsubscribe)
        },
        // onError
        (error) => {
          reject(error);
        }
      );

      return unsubscribe;
    });
  }

  // create consortium item
  async create(item: IConsortium) {
    const path = this.path;
    if (!path) return;

    const itemRef = doc(collection(db, path));
    item.id = itemRef.id;

    // create in db
    try {
      await setDoc(itemRef, item, {
        merge: true,
      });
      // create in store
      this.store.consortium.load([item]);
    } catch (error) {
      console.log(error);
    }
  }

  // update consortium item
  async update(item: IConsortium) {
    const path = this.path;
    if (!path) return;

    // update in db.
    try {
      await updateDoc(doc(db, path, item.id), {
        ...item,
      });
      // update in store
      this.store.consortium.load([item]);
    } catch (error) {
      console.log(error);
    }
  }

  // delete consortium item
  async delete(item: IConsortium) {
    const path = this.path;
    if (!path) return;

    try {
      // remove from db
      await deleteDoc(doc(db, path, item.id));
      // Remove from memory
      this.store.consortium.remove(item.id);
    } catch (error) {
      console.log(error);
    }
  }
}
