import AppStore from "../stores/AppStore";

import AppApi from "./AppApi";
import {
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  Unsubscribe,
} from "firebase/auth";

import { doc, getDoc, onSnapshot, Timestamp } from "firebase/firestore";
import { IUser } from "../models/User";
import { auth, db } from "../config/firebase-config";

export default class AuthApi {
  constructor(private api: AppApi, private store: AppStore) {
    this.handleAuthStateChange();
  }

  // handle auth change.
  private handleAuthStateChange() {
    onAuthStateChanged(auth, async (firebaseUser) => {
      this.store.auth.setLoading(true); // start loading.

      if (!firebaseUser) {
        this.logOut();
        this.store.auth.setLoading(false); // start loading.
        return;
      }

      try {
        // get user info & tenant id.
        const userInfo = await this.getUserInfo(firebaseUser.uid);
        // Update me snapshot
        this.getUserInfoSnapshot(firebaseUser.uid)

        if (!userInfo) return; // user not found.
      } catch (error) {
        console.log(error);
        this.logOut();
      }
    });
  }

  // login
  async signIn(email: string, password: string) {
    return await signInWithEmailAndPassword(auth, email, password);
  }

  // sign up
  async signUp(user: IUser, password: string) {
    // create account
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      user.email,
      password
    );
    const uid = userCredentials.user.uid;
    // update user creation date.
    user.createdOn = Timestamp.now().toMillis();
    // create user image
    return await this.api.user.createUserDoc(user, uid);
  }

  // logout
  async logOut() {
    try {
      await signOut(auth);
      // console.log("Logged out!");
    } catch (error) {
      // console.log("Error, sign-out failed.");
      console.log(error);
    }

    // Remove user from store.
    this.store.auth.logOut();
  }


  // reset password
  async triggerResetEmail(email: string) {
    return await sendPasswordResetEmail(auth, email);
  }

  private async getUserInfoSnapshot(uid: string) {
    return await new Promise<IUser | null>((resolve, reject) => {
      onSnapshot(
        doc(db, "users", uid),
        (doc) => {
          // Ensure the user is exist.
          if (!doc || !doc.exists()) {
            resolve(null)
          } else {
            const user = { ...doc.data(), uid } as IUser;
            // Set user to store.
            this.store.auth.logInMeSnapshot(user);
            resolve(user);
          }

        },// onError
        (error) => {
          reject(error);
        })
    })

  }

  private async getUserInfo(uid: string) {
    const userDoc = await getDoc(doc(db, "users", uid)); // load user doc.
    if (!userDoc || !userDoc.exists()) return null; // ensure the user is exist.

    const user = { ...userDoc.data(), uid } as IUser;
    this.store.auth.logIn(user); // set user to store.
    this.store.auth.setLoading(false); // start loading.

    return user;
  }
}
