import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../../common/models/User";
import {
  CENTER_NAV_ITEMS,
  PRIVATE_NAV_ITEMS,
  RIGHT_NAV_ITEMS,
} from "./Constant";
declare const UIkit: any;

interface INavItemProps {
  name: string | React.ReactNode;
  path: string;
  children?: React.ReactNode;
}
const NavItem = (props: INavItemProps) => {
  const element = document.querySelector(`#offcanvas-navbar`);
  const onHide = () => UIkit.offcanvas(element).hide();

  return (
    <li className="nav-item" onClick={onHide}>
      <NavLink to={props.path} className="nav-link">
        {props.name}
      </NavLink>

      {props.children}
    </li>
  );
};

interface IProps {
  user: User | null;
  signOut: () => void;
}
const OffcanvasNavbar = (props: IProps) => {
  const { user, signOut } = props;
  const navigate = useNavigate();

  const signIn = () => navigate("/sign-in");

  const onStopPropagation = (e: any) => e.stopPropagation();

  return (
    <div
      className="navbar__offcanvas"
      id="offcanvas-navbar"
      onClick={onStopPropagation}
    >
      <div className="uk-flex uk-flex-column">
        <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical">
          {CENTER_NAV_ITEMS.map((navitem) => {
            return (
              <NavItem
                name={
                  <>
                    {navitem.icon && (
                      <FontAwesomeIcon
                        className="uk-margin-small-right"
                        icon={navitem.icon}
                      />
                    )}
                    {navitem.name}
                  </>
                }
                path={navitem.path}
                key={navitem.id}
              >
                {navitem.subNavitems && (
                  <div className="nav-item--dropdown uk-navbar-dropdown">
                    <ul className="nav-item--dropdown--list uk-nav uk-navbar-dropdown-nav">
                      {navitem.subNavitems.map((subnavitem) => (
                        <li key={subnavitem.id}>
                          <NavLink to={subnavitem.path} className="nav-link">
                            {subnavitem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </NavItem>
            );
          })}

          {RIGHT_NAV_ITEMS.map((navitem) => (
            <NavItem
              key={navitem.id}
              name={
                <>
                  {navitem.icon && (
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={navitem.icon}
                    />
                  )}
                  {navitem.name}
                </>
              }
              path={navitem.path}
            />
          ))}

          <li className="uk-margin">
            <hr />
          </li>

          {PRIVATE_NAV_ITEMS.map((navitem) => (
            <NavItem
              key={navitem.id}
              name={
                <>
                  {navitem.icon && (
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={navitem.icon}
                    />
                  )}
                  {navitem.name}
                </>
              }
              path={navitem.path}
            />
          ))}
        </ul>

        {user ? (
          <button className="kit-btn-text" onClick={signOut}>
            <FontAwesomeIcon
              className="uk-margin-small-right"
              icon={faArrowRightFromBracket}
            />
            Sign out
          </button>
        ) : (
          <button className="kit-btn-text" onClick={signIn}>
            <FontAwesomeIcon
              className="uk-margin-small-right"
              icon={faArrowRightToBracket}
            />
            Sign in
          </button>
        )}
      </div>
    </div>
  );
};

export default OffcanvasNavbar;
