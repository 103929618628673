import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

interface IConsortiumTabItemProps {
  id: "details" | "members" | "finish";
  activeTab: "details" | "members" | "finish";
  children: string;
}
export const ConsortiumTabItem = (props: IConsortiumTabItemProps) => {
  const { id, activeTab, children } = props;

  const className = id === activeTab ? "tab-item tab-item--active" : "tab-item";

  let isComplete = false;
  if (activeTab === "details") isComplete = false;
  if (activeTab === "members" && id === "details") isComplete = true;
  if (activeTab === "finish" && (id === "details" || id === "members"))
    isComplete = true;

  return (
    <li className={className}>
      {children}

      {isComplete && <FontAwesomeIcon icon={faCheckDouble} />}
    </li>
  );
};

interface IConsortiumTabsProps {
  children: ReactNode;
}
const ConsortiumTabs = (props: IConsortiumTabsProps) => {
  return <ul className="tabs">{props.children}</ul>;
};

export default ConsortiumTabs;
