import { makeObservable, runInAction, toJS } from "mobx";
import AppStore from "./AppStore";
import User, { IUser } from "../models/User";
import { IConsortiumMember, defaultConsortiumMember } from "../models/ConsortiumMember";

export default class AuthStore {
  protected store: AppStore;
  me: User | null = null;
  meSnapshot: User | null = null;
  loading: boolean = true;

  constructor(store: AppStore) {
    makeObservable(this, {
      me: true,
      meSnapshot: true,
      loading: true,
      meJson: true,
    });

    this.store = store;
  }

  // get user
  get meJson() {
    return this.me ? toJS(this.me.asJson) : null;
  }

  // get user
  get meSnaptshotJson() {
    return this.meSnapshot ? toJS(this.meSnapshot.asJson) : null;
  }

  // get user as consortium member
  get meAsConsortiumMember() {
    if (!this.me) return null;

    const user = toJS(this.me.asJson);
    const _consortium: IConsortiumMember = {
      ...defaultConsortiumMember,
      uid: user.uid,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      mobilePhone: user.mobilePhone,
      email: user.email,
      photoURL: user.photoURL,
      physicalAddress: user.physicalAddress,
      country: user.country,
      passport: user.passport,
      referredBy: user.referredBy,
      createdOn: user.createdOn,
    }
    return _consortium;
  }

  // get affiliate link
  get affiliateLink() {
    return this.meJson
      ? window.location.origin + "/sign-up/" + this.meJson.uid
      : null;
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  logIn(item: IUser) {
    runInAction(() => {
      this.me = new User(this.store, item);
    });
  }

  logOut() {
    runInAction(() => {
      this.me = null;
    });
  }

  logInMeSnapshot(item: IUser) {
    runInAction(() => {
      this.meSnapshot = new User(this.store, item);
    });
  }

  logOutMeSnapshot() {
    runInAction(() => {
      this.meSnapshot = null;
    });
  }
}
