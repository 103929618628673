import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const SmallTopNavbar = () => {
  return (
    <div className="navbar navbar__top-nav">
      <div className="navbar__top-nav--container uk-container uk-container-large">
        <ul id="nav-social-links">
          <li>
            <a
              className="fb"
              href="https://www.facebook.com/namibiapropertyraffle/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              className="ig"
              href="https://instagram.com/namibiapropertyraffle/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a
              className="yt"
              href="https://youtube.com/@NamibiaPropertyRaffle-ov8is"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SmallTopNavbar;
