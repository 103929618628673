import {
  faArrowLeftLong,
  faArrowRightToBracket,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useAppContext } from "../../common/hooks/useContext";
import "./Style.scss";

const SignInPage = observer(() => {
  const { api, store } = useAppContext();

  const user = store.auth.me;
  const navigate = useNavigate();

  // Data variables & states.
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // UI variables & states
  const [loading, setLoading] = useState(false);
  const firstRender = useRef(false);
  let errorMsg = "";

  const backgroundImage = process.env.PUBLIC_URL + "/assets/property/1.png";

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // start loading
    api.auth
      .signIn(email, password)
      .then(onSuccess)
      .catch((error) => {
        // Handle sign-in error
        const errorCode = error.code;

        switch (errorCode) {
          case "auth/invalid-email":
            // Handle invalid email error
            errorMsg = "Invalid email address.";
            break;
          case "auth/user-disabled":
            // Handle user disabled error
            errorMsg =
              "User account is disabled. Please contact us at: +264(0) 81 687 6858.";
            break;
          case "auth/user-not-found":
            // Handle user not found error
            errorMsg = "User account not found. Please sign up.";
            break;
          case "auth/wrong-password":
            // Handle wrong password error
            errorMsg = "Wrong password, please click forgot password link.";
            break;
          // Add more cases for other error codes
          default:
            // Handle other errors
            errorMsg =
              "Failed to sign you in to the account! Please contact us at: +264(0) 81 687 6858.";
        }

        onError();
      });
  };

  const onError = () => {
    swal("Cannot sign in into account!", errorMsg, "error");
    onCancel();
  };

  const onSuccess = () => {
    swal("You've signed in successful!", "Enjoy property raffle!", "success");
    onCancel();
  };

  const onCancel = () => {
    setLoading(false); // stop loading
  };

  const onForgotPassword = () => {
    try {
      swal("Type your email address:", {
        content: { element: "input" },
      }).then(async (email) => {
        try {
          await api.auth.triggerResetEmail(email);
          onSuccessResetPassword();
        } catch (error) {
          onErrorResetPassword();
        }
      });
    } catch (error) {
      onErrorResetPassword();
    }
  };

  const onSuccessResetPassword = () => {
    swal(
      "Password reset email link!",
      "A password reset link has been sent to your email address.",
      "success"
    );
  };

  const onErrorResetPassword = () => {
    swal(
      "Error resetting your account!",
      "Failed to send a password reset to your email! Please contact us at: +264(0) 81 687 6858.",
      "error"
    );
  };

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      return;
    }

    const navigateAppropriately = () => {
      setLoading(false); // stop loading
      if (!user) return;
      if (
        user.asJson.accountType === "client" ||
        user.asJson.accountType === "admin"
      )
        navigate("/portal");
      else navigate("/");
    };

    navigateAppropriately();
  }, [navigate, user]);

  return (
    <div
      className="sign-in-page"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="su-p-h-100 uk-container uk-container-large">
        <div className="sign-up-form-container su-p-h-100">
          <form
            className="uk-form-stacked kit-card kit-card-default"
            onSubmit={onSubmit}
          >
            <h3 className="title">
              <NavLink className="back-to-website" to="/">
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faArrowLeftLong}
                />
                Website
              </NavLink>
              Sign In
            </h3>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-email">
                Email
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-email"
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-passowrd">
                Passowrd
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="controls">
              <button className="submit-btn" type="submit" disabled={loading}>
                {loading ? (
                  <div data-uk-spinner="ratio: .5"></div>
                ) : (
                  <FontAwesomeIcon
                    className="uk-margin-small-right"
                    icon={faArrowRightToBracket}
                  />
                )}
                Sign In
              </button>
              <p className="create-account">
                Don't have an account, <NavLink to="/sign-up">Sign Up</NavLink>
              </p>
              <button
                className="forgot-password-btn"
                type="button"
                onClick={onForgotPassword}
              >
                Forgot password
                <FontAwesomeIcon
                  className="uk-margin-small-left"
                  icon={faInfoCircle}
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default SignInPage;
