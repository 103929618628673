import Store from "./Store";
import AppStore from "./AppStore";
import { runInAction } from "mobx";
import Withdrawal, { IWithdrawal } from "../models/Withdrawal";

export default class WithdrawStore extends Store<IWithdrawal, Withdrawal> {
  items = new Map<string, Withdrawal>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IWithdrawal[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Withdrawal(this.store, item))
      );
    });
  }
}
