import React from "react";
import "./Loading.scss";

interface LoadingProps {
  isEllipsis?: boolean;
  fullHeight?: boolean;
}
export const Loading = (props: LoadingProps) => {
  if (props.isEllipsis)
    return (
      <div className={props.fullHeight ? "loader full-width-loader" : "loader"}>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );

  return (
    <div className={props.fullHeight ? "loader full-width-loader" : "loader"}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
