import {
  query,
  collection,
  onSnapshot,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { where } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { IMetadata } from "../models/Metadata";
import { IUser } from "../models/User";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class MetadataApi {
  private path: string = "metadata";

  constructor(private api: AppApi, private store: AppStore) { }

  // path getters and setters
  setPath(path: string) {
    this.path = path;
  }

  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, id), (doc) => {
      if (!doc.exists) return;
      const item = { id: doc.id, ...doc.data() } as IMetadata;

      this.store.metadata.load([item]);
    });

    return unsubscribe;
  }
}
