import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../common/components/error-boundary/ErrorBoundary";
import Slider from "react-slick";
import { useAppContext } from "../../../common/hooks/useContext";
import Advert from "../../../common/models/Advert";
import Loading from "../../../common/components/loading/Loading";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AdvertBanner.scss";

interface IAdvertProps {
  ad: Advert;
}
const AdvertItem = (props: IAdvertProps) => {
  const advert = props.ad.asJson;
  const { imageUrl, link } = advert;

  return (
    <div className="advert-item">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt="" />
      </a>
    </div>
  );
};

const AdvertBanner = () => {
  const { store, api } = useAppContext();

  const adverts = store.advert.all;

  // Initialize states
  const [loading, setLoading] = useState(false);

  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          variableWidth: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  // Load the adverts
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await api.advert.getAll();
      setLoading(false);
    };
    load();
  }, [api.advert, api.metadata]);

  if (loading) <Loading isEllipsis />;

  if (adverts.length === 0) return <></>;

  return (
    <ErrorBoundary>
      <div className="advert-banner uk-margin-large">
        {/* <h1 className="page-heading uk-text-center">Endorsements</h1> */}

        <Slider {...settings}>
          {adverts.map((ad) => (
            <AdvertItem ad={ad} key={ad.asJson.id} />
          ))}
        </Slider>
      </div>
    </ErrorBoundary>
  );
};

export default AdvertBanner;
