import {
  query,
  collection,
  onSnapshot,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { getDocs, where } from "firebase/firestore";
import { db } from "../config/firebase-config";
import { IUser, defaultUser } from "../models/User";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class AffiliateApi {
  private path: string = "users";

  constructor(private api: AppApi, private store: AppStore) { }

  // path getters and setters
  setPath(path: string) {
    this.path = path;
  }

  async getAll() {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.affiliate.removeAll();

    // create the query
    const $query = query(collection(db, path)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.affiliate.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getAllDocs(uid: string) {
    // get the db path
    const path = this.path;
    if (!path) return [];

    // create the query
    const $query = query(collection(db, path), where("referredBy", "==", uid)); // query

    // on snapshot
    const querySnapshot = await getDocs($query);
    const items: IUser[] = querySnapshot.docs.map((doc) => ({ ...defaultUser, uid: doc.id, ...doc.data() } as IUser))

    // // Update store
    // this.store.tickets.load(items);

    // Return items
    return items
  }

  async getAllByUser(uid: string) {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.affiliate.removeAll();

    // create the query
    const $query = query(collection(db, path), where("referredBy", "==", uid)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.affiliate.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, id), (doc) => {
      if (!doc.exists) return;
      const item = { uid: doc.id, ...doc.data() } as IUser;

      this.store.affiliate.load([item]);
    });

    return unsubscribe;
  }
}
