import { IConsortiumMember } from "./ConsortiumMember";
import Model from "./Model";

// Purchase type -> Individual or Consortium
export type IPurchaseType = "individual" | "consortium"

export const defaultConsortium: IConsortium = {
  id: "",
  name: "",
  uids: [],
  members: [],
  tickets: 0,
  createdOn: Date.now(),
};

export interface IConsortium {
  id: string;
  name: string;
  uids: string[];
  members: IConsortiumMember[];
  tickets: number;
  createdOn: number;
}

export default class Consortium extends Model<IConsortium>  { }
