import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoggedOutRoutes from "./logged-out/pages/LoggedOutRoutes";
import LoggedInPortalRoutes from "./logged-in/pages/LoggedInPortalRoutes";
import { MainApp } from "./common/models/App";
import Loading from "./common/components/loading/Loading";
import { PrivateRoute } from "./common/components/private-route/PrivateRoute";
import { AppContext } from "./common/hooks/useContext";
import SnackbarManager from "./common/components/snackbar/SnackbarManager";
import SignInPage from "./logged-out/pages/SignInPage";
import SignUpPage from "./logged-out/pages/SignUpPage";
import Modal from "./common/components/modal/Modal";
import { MODAL_NAMES } from "./common/config/constants";
import OrderSummaryModal from "./logged-in/modals/order-summary/OrderSummaryModal";
import RaffleQuantityModal from "./logged-in/modals/raffle-quantity/RaffleQuantityModal";
import "aos/dist/aos.css";

// fonts
import "./common/fonts/BROTHER/BROTHER-Bold.otf";
import "./common/fonts/BROTHER/BROTHER-Bold.ttf";
import "./common/fonts/BrightSight/BRIGHTSIGHT02.otf";
import "./common/fonts/BrightSight/BRIGHTSIGHT02.ttf";

const AOS = require("aos");

const App = () => {
  const app = new MainApp();
  const { store, api, ui } = app;

  useEffect(() => {
    AOS.init({
      offset: -50,
      delay: 0,
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <AppContext.Provider value={{ store, api, ui }}>
        <BrowserRouter>
          <Routes>
            {/* Landing page */}
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-up/:invitedBy" element={<SignUpPage />} />
            <Route path="/*" element={<LoggedOutRoutes />} />

            {/* Portal */}
            <Route
              path="/portal/*"
              element={
                <Suspense fallback={<Loading fullHeight={true} />}>
                  <PrivateRoute>
                    <LoggedInPortalRoutes />
                  </PrivateRoute>
                </Suspense>
              }
            />

            {/* Redirects */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>

        <SnackbarManager />
      </AppContext.Provider>

      <Modal modalId={MODAL_NAMES.RAFFLE_QTY_MODAL}>
        <RaffleQuantityModal />
      </Modal>
      <Modal modalId={MODAL_NAMES.ORDER_SUMMARY_MODAL}>
        <OrderSummaryModal />
      </Modal>
    </div>
  );
};

export default App;
