/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import "./CarouselSlider.scss";

interface ISliderItemProps {
  backroundImage: string;
  logo: string;
  caption?: string;
}
export const SliderItem = (props: ISliderItemProps) => {
  return (
    <li>
      <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
        <img
          className="bg-image"
          src={props.backroundImage}
          alt={props.caption}
          data-uk-cover
        />
      </div>
      <div className="text-overlay">
        <img src={props.logo} alt={props.caption} />
        {props.caption && <h2>{props.caption}</h2>}
      </div>
    </li>
  );
};

interface ISliderProps {
  children: React.ReactNode;
}
export const CarouselSlider = (props: ISliderProps) => {
  return (
    <div
      className="carousel__slider uk-position-relative uk-visible-toggle"
      data-uk-slideshow="autoplay: true; animation: push"
      tabIndex={-1}
    >
      <ul className="uk-slideshow-items">{props.children}</ul>
      <ul className="uk-slideshow-nav uk-dotnav"></ul>

      <a
        className="slider-control-btn uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-previous
        data-uk-slideshow-item="previous"
      ></a>
      <a
        className="slider-control-btn uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-next
        data-uk-slideshow-item="next"
      ></a>
    </div>
  );
};

export default CarouselSlider;
