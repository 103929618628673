import {
  faArrowLeft,
  faArrowRight,
  faFloppyDisk,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Dispatch,
  FormEvent,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import swal from "sweetalert";
import { IConsortiumMember } from "../../../common/models/ConsortiumMember";

interface IConsortiumMemberAccountProps {
  member: IConsortiumMember;
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  fixed?: boolean;
}
export const ConsortiumMemberAccount = (
  props: IConsortiumMemberAccountProps
) => {
  // extract props
  const { member, members, setMembers, fixed = false } = props;

  const onRemoveMember = () => {
    const _newMembers = members.filter((m) => m.email !== member.email);
    setMembers(_newMembers);
  };

  return (
    <div className="uk-margin-small consortium-member-account">
      <p className="consortium-member-account-name">{member.fullName}</p>
      {!fixed && (
        <button
          className="delete-member"
          type="button"
          onClick={onRemoveMember}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </div>
  );
};

interface IConsortiumMemberEmailProps {
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  findUserByEmail: (email: string) => Promise<IConsortiumMember[] | undefined>;
}
const ConsortiumMemberEmail = (props: IConsortiumMemberEmailProps) => {
  // Extract props;
  const { members, setMembers, findUserByEmail } = props;

  // Initialize component states
  const [email, setEmail] = useState("");

  // Find member form db.
  const searchMemberByEmail = async () => {
    const _members = await findUserByEmail(email);
    // If member found.
    if (_members) addMemberToConsortium(_members);
    // Else notify user, invalid account.
  };

  // Add member to consortium if exists in db.
  const addMemberToConsortium = (_members: IConsortiumMember[]) => {
    // Check members length
    if (members.length >= 5) {
      onAlert(
        "Maximum number of members!",
        "You've reaved the maximum number of allowed members (Max. of 5 members per Consortium)",
        "error",
        3000
      );
      return;
    }
    // Check if member already added to list.
    const memberIndex = members.findIndex((m) => m.email === email);

    if (memberIndex === -1) {
      setMembers([...members, ..._members]);
      setEmail("");
    } else {
      onAlert(
        "Account already added!",
        "Member already added to Consortium",
        "error"
      );
    }
  };

  const onAlert = (
    title: string,
    msg: string,
    icon: string = "success",
    timer: number = 2000
  ) => {
    swal({
      title: title,
      text: msg,
      icon: icon,
      timer: timer,
    });
  };

  return (
    <div className="uk-margin consortium-member-email">
      <label className="uk-form-label" htmlFor="cstium-email">
        Enter Member's Email Address
      </label>
      <div className="uk-form-controls consortium-member-email-form-controls">
        <input
          className="uk-input"
          id="cstium-email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button
          className="validate-member"
          type="button"
          onClick={searchMemberByEmail}
        >
          ADD <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
};

interface IConsortiumStep2MembersProps {
  setActiveTab: Dispatch<SetStateAction<"details" | "members" | "finish">>;
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  findUserByEmail: (email: string) => Promise<IConsortiumMember[] | undefined>;
  children: ReactNode;
  onSave: () => Promise<void>;
}
const ConsortiumStep2Members = (props: IConsortiumStep2MembersProps) => {
  // extract props
  const {
    setActiveTab,
    members,
    setMembers,
    findUserByEmail,
    children,
    onSave,
  } = props;

  // initialize states
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (members.length >= 1) {
        await onSave();
      } else {
        onAlert(
          "Minimum accounts!",
          "A minimum number of 2 members is required",
          "error"
        );
      }
    } catch (error) {
      onAlert("Error saving!", "Failed to save consortium", "error");
    } finally {
      setLoading(false);
    }
  };

  const onPrevious = (): void => setActiveTab("details");

  const onAlert = (
    title: string,
    msg: string,
    icon: string = "success",
    timer: number = 2000
  ) => {
    swal({
      title: title,
      text: msg,
      icon: icon,
      timer: timer,
    });
  };

  return (
    <form className="consortium-members" onSubmit={onSubmit}>
      <div className="tab-header">
        <h5 className="tab-header-title">Add Consortium Member</h5>
        <p className="tab-header-description">
          A Consortium can have a Min. number of 2 members, and a Max. number of
          5 members.
        </p>
      </div>

      {children}

      {/* Hide if the number of members are enough/reached max */}
      {members.length < 5 && (
        <ConsortiumMemberEmail
          members={members}
          setMembers={setMembers}
          findUserByEmail={findUserByEmail}
        />
      )}

      <div className="controls">
        <button
          className="kit-btn kit-btn-muted"
          type="button"
          onClick={onPrevious}
        >
          <FontAwesomeIcon
            className="uk-margin-small-right"
            icon={faArrowLeft}
          />
          Previous step
        </button>

        <button className="kit-btn kit-btn" type="submit" disabled={loading}>
          Save
          <FontAwesomeIcon
            className="uk-margin-small-left"
            icon={faFloppyDisk}
          />
        </button>
      </div>
    </form>
  );
};

export default ConsortiumStep2Members;
