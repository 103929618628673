import { ChangeEvent, FormEvent, useState } from "react";
import { hideModal } from "../../../common/components/modal/Modal";
import { MODAL_NAMES } from "../../../common/config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGavel,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../common/hooks/useContext";
import {
  IWithdrawal,
  defaultWithdrawal,
} from "../../../common/models/Withdrawal";
import { observer } from "mobx-react-lite";
import { round } from "../../../common/utils/Math";
import swal from "sweetalert";
import "./ApplyForWithdraw.scss";

const ApplyForWithdraw = observer(() => {
  const { api, store } = useAppContext();

  const me = store.auth.meSnaptshotJson;

  const [withdrawal, setWithdrawal] = useState<IWithdrawal>({
    ...defaultWithdrawal,
  });
  // const [me, setMe] = useState<IUser | null>(() => store.auth.meSnaptshotJson);
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState("");
  // const [amount, setAmount] = useState("");

  const commission = me?.commission || 0;
  const pendingWithdrawBalance = me?.pendingWithdrawBalance || 0;
  const balance = commission - pendingWithdrawBalance;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!me || amountError.length !== 0) return; // TODO show error message
      setLoading(true);

      // Don't process if balance is zero.
      if (balance <= 0) {
        swal({
          title: "Error!",
          text: "Youve insuffient funds to complete this transaction!",
          icon: "error",
          timer: 2000,
        });
        return;
      }

      // update uid and userName
      const _withdrawal: IWithdrawal = {
        ...withdrawal,
        uid: me.uid,
        userName: me.fullName,
        amount: round(balance),
      };
      await api.withdraw.create(_withdrawal);
      swal({
        title: "Success!",
        text: "Applied for withdrawal!",
        icon: "success",
        timer: 2000,
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: "Faied to apply for withdrawal",
        icon: "error",
        timer: 2000,
      });
    } finally {
      onCancel();
    }
  };

  const onCancel = () => {
    hideModal(MODAL_NAMES.WITHDRAW_MODAL);
    setLoading(false);
    // setAmount("");
    setAmountError("");
    setWithdrawal({ ...defaultWithdrawal });
  };

  // const onAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;

  //   if ((Number(value) || 0) > Number(balance))
  //     setAmountError("Insufficient funds, your balance is N$" + balance);
  //   else setAmountError("");

  //   // Set amount in dollars
  //   setAmount(value);

  //   // Set amount in cents
  //   setWithdrawal({
  //     ...withdrawal,
  //     amount: round(Number(e.target.value) * 100),
  //   });
  // };

  return (
    <div className="withdraw-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <h3 className="title uk-modal-title">Apply for Withdraw</h3>

      <div className="dialog-content uk-position-relative">
        <form
          className="form uk-form-stacked"
          data-uk-grid
          onSubmit={handleSubmit}
        >
          <div className="uk-width-1-1">
            <div className="current-balance">
              <p className="label">Balance</p>
              <p className="balance">N${round(balance / 100)}</p>
            </div>
          </div>

          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="w-cardholder">
              Bank account number
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="w-cardholder"
                type="text"
                placeholder="Account number"
                value={withdrawal.bankAccountNumber}
                onChange={(e) =>
                  setWithdrawal({
                    ...withdrawal,
                    bankAccountNumber: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="w-account-type">
              Bank account type
            </label>
            <div className="uk-form-controls">
              <select
                className="uk-select"
                id="w-account-type"
                value={withdrawal.bankAccountType || ""}
                onChange={(e) =>
                  setWithdrawal({
                    ...withdrawal,
                    bankAccountType: e.target.value,
                  })
                }
                required
              >
                <option value="" disabled>
                  Select account type
                </option>
                <option value="Cheque">Cheque</option>
                <option value="Savings">Savings</option>
              </select>
            </div>
          </div>
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="w-bic-code">
              SWIFT, BIC or Branch code
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="w-bic-code"
                type="text"
                placeholder="SWIFT / BIC / Branch"
                value={withdrawal.bankSwiftCode}
                onChange={(e) =>
                  setWithdrawal({
                    ...withdrawal,
                    bankSwiftCode: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>

          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="w-bank-name">
              Bank name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="w-bank-name"
                type="text"
                placeholder="Bank Name"
                value={withdrawal.bankName}
                onChange={(e) =>
                  setWithdrawal({ ...withdrawal, bankName: e.target.value })
                }
                required
              />
            </div>
          </div>
          <div className="uk-width-1-1">
            <label className="uk-form-label" htmlFor="w-bank-address">
              Bank address
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                id="w-bank-address"
                type="text"
                placeholder="City, Country"
                value={withdrawal.bankAddress}
                onChange={(e) =>
                  setWithdrawal({ ...withdrawal, bankAddress: e.target.value })
                }
                required
              />
            </div>
          </div>

          <div className="footer uk-text-center uk-width-1-1">
            <button
              className="kit-btn kit-btn-lg kit-btn-success"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div data-uk-spinner="ratio: .5"></div>
              ) : (
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faMoneyBillTransfer}
                />
              )}
              Withdraw
            </button>

            <div className="terms-and-conditions uk-margin-top">
              <a href="/TCs">
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faGavel}
                />
                Terms & Conditions
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ApplyForWithdraw;
