import React from "react";
import PageHeading from "./PageHeading";
import "./SectionParagraphContent.scss";

interface IParagraphProps {
  children: string | React.ReactNode;
  textCenter?: boolean;
  additionalClass?: string;
}
export const Paragraph = (props: IParagraphProps) => {
  const className = props.textCenter
    ? "section-content--paragraph section-content--paragraph--text-center " +
      props.additionalClass
    : "section-content--paragraph " + props.additionalClass;
  return (
    <p className={className} data-aos="fade-up">
      {props.children}
    </p>
  );
};

interface IProps {
  heading: string | React.ReactNode;
  children?: React.ReactNode; //'children' is a prop that accepts any Paragraph components';
}
const SectionParagraphContent = (props: IProps) => {
  return (
    <div className="section-content" data-aos="fade-up">
      <PageHeading children={props.heading} />
      <div className="section-content--paragraph__list ">{props.children}</div>
    </div>
  );
};

export default SectionParagraphContent;
