import {
  faArrowLeft,
  faDownload,
  faEllipsis,
  faPeopleGroup,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import NoData from "../../common/components/no-data/NoData";
import { observer } from "mobx-react-lite";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../common/hooks/useContext";
import Ticket from "../../common/models/Ticket";
import { sortTicket } from "../../common/utils/Sort";
import Loading from "../../common/components/loading/Loading";
import { ticketProcessor } from "../../common/utils/String";
import TicketPDFGen from "../../common/pdf/TicketPDFGen";
import { round } from "../../common/utils/Math";
import "./Style.scss";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import Modal, { showModal } from "../../common/components/modal/Modal";
import { MODAL_NAMES } from "../../common/config/constants";
import ConsortiumMembersModal from "../modals/consortium-members-modal/ConsortiumMembersModal";

interface IBuyTicketCardProps {
  onBuy: () => void;
}
const BuyTicketCard = (props: IBuyTicketCardProps) => {
  const { onBuy } = props;
  return (
    <div
      className="buy-ticket-card kit-card kit-card-sm kit-card-default"
      data-aos="fade-up"
    >
      <div className="ticket--img-container">
        <img src={process.env.PUBLIC_URL + "/assets/tickets/c.png"} alt="" />
      </div>
      <div className="footer">
        <button onClick={onBuy}>
          <FontAwesomeIcon icon={faTicket} /> Buy Tickets
        </button>
      </div>
    </div>
  );
};

interface IDashboardCardProps {
  type?: "negative" | "positive" | "neutral";
  children: ReactNode;
}
const DashboardCard = (props: IDashboardCardProps) => {
  const { type = "neutral", children } = props;

  const icon =
    type === "negative"
      ? process.env.PUBLIC_URL + "/icons/8041780.png"
      : type === "neutral"
      ? process.env.PUBLIC_URL + "/icons/2558898.png"
      : process.env.PUBLIC_URL + "/icons/3223311.png";

  return (
    <div
      className={`dashboard-card dashboard-card--${type} kit-card kit-card-default`}
      data-aos="fade-up"
    >
      {children}
      <div className="icon">
        <img src={icon} alt="" />
      </div>
    </div>
  );
};

interface IHeaderProps {
  noOfTickets: number;
  onBuy: () => void;
}
const Header = (props: IHeaderProps) => {
  const { noOfTickets, onBuy } = props;

  return (
    <div className="kit-header">
      <div
        className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
        data-uk-grid
      >
        <div>
          <DashboardCard type="positive">
            <div className="content">
              <div className="top">
                <h3 className="digit">{noOfTickets}</h3>
              </div>
              <h3 className="title">Consortium Tickets</h3>
              <p className="description">Number of tickets bought.</p>
            </div>
          </DashboardCard>
        </div>
        <div>
          <BuyTicketCard onBuy={onBuy} />
        </div>
      </div>
    </div>
  );
};

const TableHeader = () => {
  return (
    <thead>
      <tr className="kit-table-header" data-aos="fade-up">
        <th>Ticket No.</th>
        <th>Date</th>
        <th>Price</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
  );
};

interface ITableRowProps {
  ticket: Ticket;
}
const TableRow = (props: ITableRowProps) => {
  const { id, consortiumName, price, isPaid, isValid, createdOn } =
    props.ticket.asJson;
  const code = ticketProcessor(id);
  const date = new Date(createdOn).toLocaleDateString();
  const displayDate = date === "Invalid Date" ? "-" : date;

  const onDownload = () => {
    try {
      TicketPDFGen(`${consortiumName} Consortium`, code, date);
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  const onError = () => {
    swal(
      "Error!",
      "Failed to download your ticket! Please contact us at: +264(0) 81 129 4067.",
      "error"
    );
  };

  const onSuccess = () => {
    swal("Download has started!", "Enjoy property raffle!", "success");
  };

  return (
    <tr className="kit-table-row" data-aos="fade-up">
      <td data-label="Ticket No.">{code}</td>
      <td data-label="Date">{displayDate}</td>
      <td data-label="Price">N${round(price / 100)}</td>
      <td data-label="Status">
        {isPaid && (
          <Fragment>
            {isValid ? (
              <div className="status status-positive">Paid</div>
            ) : (
              <div className="status status-warning">Not Verified</div>
            )}
          </Fragment>
        )}
      </td>
      <td className="uk-text-right" data-label="Actions">
        <button className="kit-btn" onClick={onDownload}>
          <FontAwesomeIcon icon={faDownload} /> Download Ticket
        </button>
      </td>
    </tr>
  );
};

interface ITableProps {
  tickets: Ticket[];
}
const Table = (props: ITableProps) => {
  const { tickets } = props;

  if (tickets.length === 0)
    return (
      <NoData>
        No raffles found on the system! <br /> Buy tickets to stand a chance to
        win one of the prizes.
      </NoData>
    );

  return (
    <div className="table-container">
      <table className="kit-table">
        <TableHeader />

        <tbody>
          {tickets.map((ticket) => (
            <TableRow key={ticket.asJson.id} ticket={ticket} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ConsortiumReadPage = observer(() => {
  const { api, store } = useAppContext();

  // Initialize variables
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const consortium = store.consortium.getItemById(id || "");

  // List all affiliate users.
  const tickets = useMemo(
    () => store.ticket.all.sort(sortTicket),
    [store.ticket.all]
  );

  // Initialize states
  const [loading, setLoading] = useState(false);

  // Buy tickets
  const onBuy = () => {
    if (!store.auth.me)
      swal({
        title: "Sign up!",
        text: "Create an account to start buying tickets!",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else navigate("/portal/checkout");
  };

  // View consortium members
  const onViewMembers = () => showModal(MODAL_NAMES.CONSORTIUM_MEMBERS_MODAL);

  // Go back to consortium
  const onGoBack = () => navigate("/portal/consortium");

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (id) {
        await api.ticket.getAllByConsortium(id);
        await api.consortium.getById(id);
      }
      setLoading(false);
    };
    load();
  }, [api.consortium, api.ticket, id]);

  if (loading) return <Loading fullHeight isEllipsis />;

  // TODO: create proper error page.
  if (!consortium) return <h2>Error!, Consortium not found!</h2>;

  return (
    <ErrorBoundary>
      <div className="raffle-page">
        <div className="uk-section uk-section-small uk-section-muted">
          <div className="uk-container uk-container-large">
            <Header noOfTickets={tickets.length} onBuy={onBuy} />
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-large">
            <div className="section-header" data-aos="fade-up">
              <h3 className="title title-go-back" onClick={onGoBack}>
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faArrowLeft}
                />
                {consortium.asJson.name} Consortium
              </h3>

              <div>
                <button className="kit-btn kit-btn-success" onClick={onBuy}>
                  <FontAwesomeIcon icon={faTicket} /> Buy Tickets
                </button>
                <button
                  className="kit-btn kit-btn-success uk-margin-small-left"
                  onClick={onViewMembers}
                >
                  <FontAwesomeIcon icon={faPeopleGroup} /> View Members
                </button>
              </div>
            </div>

            <Table tickets={tickets} />
          </div>
        </div>
      </div>

      <Modal modalId={MODAL_NAMES.CONSORTIUM_MEMBERS_MODAL}>
        <ConsortiumMembersModal consortium={consortium.asJson} />
      </Modal>
    </ErrorBoundary>
  );
});

export default ConsortiumReadPage;
