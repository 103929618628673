import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import Blogs from "../components/blogs/Blogs";
import "./Style.scss";

const BlogsPage = () => {
  return (
    <ErrorBoundary>
      <div className="blogs-page">
        <div className="uk-section uk-section-small uk-section-default">
          <div className="uk-container uk-container-large">
            <Blogs />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default BlogsPage;
