import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase-config";
import { IInitialTransactionRequest, ITokenResponse } from "../models/Paygate";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class CheckoutApi {
  constructor(private api: AppApi, private store: AppStore) { }

  // Initiate transaction
  async intiateTransaction(item: IInitialTransactionRequest) {
    try {
      // init functions
      const initiateTrans = httpsCallable(functions, "initiateTransaction");
      const response = await initiateTrans(item) as any;
      return response.data as ITokenResponse
      // update in local storage
    } catch (error) {
      console.log(error);
      throw new Error("Error getting token!");
    }
  }
}
