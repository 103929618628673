import {
  faArrowLeftLong,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useAppContext } from "../../common/hooks/useContext";
import { defaultUser, IUser } from "../../common/models/User";
import InputCountry from "../components/input-country/InputCountry";
import { observer } from "mobx-react-lite";
import "./Style.scss";

const SignUpPage = observer(() => {
  const { api, store } = useAppContext();
  const { invitedBy } = useParams<string>();

  const $user = store.auth.me;
  const navigate = useNavigate();

  // Data variables & states.
  const [user, setUser] = useState<IUser>({ ...defaultUser });
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState(() => invitedBy || "");
  const [acceptTerms, setAcceptTerms] = useState(false);

  // UI variables & states
  const [loading, setLoading] = useState(false);
  const backgroundImage = process.env.PUBLIC_URL + "/assets/property/1.png";
  const firstRender = useRef(false);
  let errorMsg = "";

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!acceptTerms) return;

    setLoading(true); // start loading
    const _user: IUser = {
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      referredBy: referralCode,
    };
    api.auth
      .signUp(_user, password)
      .then(onSuccess)
      .catch((error) => {
        // Handle sign-in error
        const errorCode = error.code;

        switch (errorCode) {
          case "auth/invalid-email":
            // Handle invalid email error
            errorMsg = "Invalid email address.";
            break;
          case "auth/weak-password":
            // Handle weak password error
            errorMsg =
              "Password is weak, please provide password more than 6 characters long.";
            break;
          case "auth/email-already-in-use":
            // Handle email already in use error
            errorMsg =
              "Email account already in use. Please login or sign up with another email address.";
            break;
          // Add more cases for other error codes
          default:
            // Handle other errors
            errorMsg =
              "Failed to sign up! Please contact us at: +264(0) 81 687 6858.";
        }

        onError();
      });
  };

  const onError = () => {
    swal("Error creating account!", errorMsg, "error");
    onCancel();
  };

  const onSuccess = () => {
    swal("Account created successful!", "Please continue to login!", "success");
    onCancel();
  };

  const onCancel = () => {
    setLoading(false); // stop loading
    setUser({ ...defaultUser });
  };

  const onForgotPassword = () => {
    try {
      swal("Type your email address:", {
        content: { element: "input" },
      }).then(async (email) => {
        try {
          await api.auth.triggerResetEmail(email);
          onSuccessResetPassword();
        } catch (error) {
          onErrorResetPassword();
        }
      });
    } catch (error) {
      onErrorResetPassword();
    }
  };

  const onSuccessResetPassword = () => {
    swal(
      "Password reset email link!",
      "A password reset link has been sent to your email address.",
      "success"
    );
  };

  const onErrorResetPassword = () => {
    swal(
      "Error resetting your account!",
      "Failed to send a password reset to your email! Please contact us at: +264(0) 81 687 6858.",
      "error"
    );
  };

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      return;
    }

    const navigateAppropriately = () => {
      setLoading(false); // stop loading
      if (!$user) return;
      if ($user.asJson.accountType === "client") navigate("/portal");
      else if ($user.asJson.accountType === "admin") navigate("/admin");
      else navigate("/");
    };

    navigateAppropriately();
  }, [navigate, $user]);

  return (
    <div
      className="sign-up-page"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="su-p-h-100 uk-container uk-container-large">
        <div className="sign-up-form-container su-p-h-100">
          <form
            className="uk-form-stacked kit-card kit-card-default"
            onSubmit={onSubmit}
          >
            <h3 className="title">
              <NavLink className="back-to-website" to="/">
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faArrowLeftLong}
                />
                Website
              </NavLink>
              Sign Up
            </h3>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-firstname">
                First Name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-firstname"
                  type="text"
                  placeholder="First name"
                  value={user.firstName}
                  onChange={(e) =>
                    setUser({ ...user, firstName: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-lastname">
                Last Name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-lastname"
                  type="text"
                  placeholder="Last name"
                  value={user.lastName}
                  onChange={(e) =>
                    setUser({ ...user, lastName: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-passport">
                National ID/Passport
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-passport"
                  type="text"
                  placeholder="National ID/Passport"
                  value={user.passport}
                  onChange={(e) =>
                    setUser({ ...user, passport: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-email">
                Email
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-email"
                  type="email"
                  placeholder="Email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-contact-no">
                Contact Number
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-contact-no"
                  type="tel"
                  placeholder="Contact Number"
                  value={user.mobilePhone}
                  onChange={(e) =>
                    setUser({ ...user, mobilePhone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-subject">
                Referral Code
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-subject"
                  type="text"
                  placeholder="Referral Code"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="su-passowrd">
                Password
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  id="su-password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <InputCountry
              value={user.country}
              onChange={(e) => setUser({ ...user, country: e.target.value })}
            />
            <div className="uk-margin">
              <label
                className="uk-form-label"
                htmlFor="su-terms-and-conditions"
              >
                <input
                  className="uk-checkbox uk-margin-small-right"
                  id="su-terms-and-conditions"
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                  required
                />
                Accept Terms & Conditions.{" "}
                <NavLink to="/TCs">Read more</NavLink>
              </label>
            </div>
            <div className="controls">
              <button className="submit-btn" type="submit" disabled={loading}>
                {loading ? (
                  <div data-uk-spinner="ratio: .5"></div>
                ) : (
                  <FontAwesomeIcon
                    className="uk-margin-small-right"
                    icon={faArrowRightToBracket}
                  />
                )}
                Create Account
              </button>
              <p className="sign-in">
                Already have an account,{" "}
                <NavLink to="/sign-in">Sign In</NavLink>
              </p>
              <button
                className="forgot-password-btn"
                type="button"
                onClick={onForgotPassword}
              >
                Forgot password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default SignUpPage;
