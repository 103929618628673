import {
  query,
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getDocs, where } from "firebase/firestore";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { authWorker, db, storage } from "../config/firebase-config";
import { IUser, defaultUser } from "../models/User";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class UserApi {
  private path: string = "users";

  constructor(private api: AppApi, private store: AppStore) { }

  // path getters and setters
  setPath(path: string) {
    this.path = path;
  }

  async getAll() {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.user.removeAll();

    // create the query
    const $query = query(collection(db, path), where("userType", "==", "User")); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ ...defaultUser, uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getAllByTenant(tenantId: string) {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.user.removeAll();

    // create the query
    const $query = query(
      collection(db, path),
      where("tenantId", "==", tenantId)
    ); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ ...defaultUser, uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, id), (doc) => {
      if (!doc.exists) return;
      const item = { ...defaultUser, uid: doc.id, ...doc.data() } as IUser;

      this.store.user.load([item]);
    });

    return unsubscribe;
  }


  async getByEmail(email: string) {
    // get the db path
    const path = this.path;
    if (!path) return;

    // create the query
    const $query = query(collection(db, path), where("email", "==", email)); // query

    try {
      // Wait for the items to be loaded
      const querySnapshot = await getDocs($query);
      const items: IUser[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...defaultUser, uid: doc.id, ...doc.data() } as IUser);
      });

      // Load the items
      this.store.user.load(items);

      // Return the items
      return items;
    } catch (error) {
      throw new Error("Cannot retrieve data");
    }
  }


  // create employee
  async createUserDoc(item: IUser, uid: string, pic?: File) {
    const path = this.path;
    if (!path) return;

    if (pic)
      try {
        const url = await this.uploadPic(pic);
        item.photoURL = url;
      } catch (error) {
        throw new Error("Failed to upload pic");
      }

    // create in db
    try {
      item.uid = uid; // update uid
      await setDoc(doc(db, path, uid), item, {
        merge: true,
      });
      // create in store
      this.store.user.load([item]);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to create employee");
    }
  }

  // create employee
  async create(item: IUser, password: string, pic?: File) {
    const path = this.path;
    if (!path) return;

    if (pic)
      try {
        const url = await this.uploadPic(pic);
        item.photoURL = url;
      } catch (error) {
        throw new Error("Failed to upload pic");
      }

    // create in db
    try {
      const user = await this.createUserAccount(item, password);

      item.uid = user.uid; // set uid

      await setDoc(doc(db, path, user.uid), item, {
        merge: true,
      });
      // create in store
      this.store.user.load([item]);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to create employee");
    }
  }

  private async createUserAccount(item: IUser, password: string) {
    try {
      const userCred = await createUserWithEmailAndPassword(
        authWorker,
        item.email,
        password
      );
      return userCred.user;
    } catch (error) {
      throw new Error("Failed to create user");
    }
  }

  /**
   * Use to update doc in db.
   */
  async update(item: IUser, pic?: File) {
    const path = this.path;
    if (!path) return;

    // current photo url -> to-be deleted
    let prevPhotoURL = null;

    if (pic)
      try {
        const url = await this.uploadPic(pic);
        prevPhotoURL = item.photoURL;
        item.photoURL = url;
      } catch (error) {
        throw new Error("Failed to upload pic");
      }

    // update in db
    try {
      await updateDoc(doc(db, path, item.uid), {
        ...item,
      });
      // update in store
      this.store.user.load([item]);
    } catch (error) {
      console.log(error);
    }

    // delete prevPhoto
    if (prevPhotoURL)
      try {
        await this.deletePic(prevPhotoURL);
      } catch (error) {
        throw new Error("Couldn't delete file.");
      }
  }

  // delete employee / carer
  async delete(item: IUser) {
    const path = this.path;
    if (!path) return;

    // remove from db
    try {
      await deleteDoc(doc(db, path, item.uid)); // remove from store

      const photoURL = item.photoURL;
      if (photoURL) await this.deletePic(photoURL);

      this.store.user.remove(item.uid); // Remove from memory
    } catch (error) {
      console.log(error);
    }
  }

  private async deletePic(url: string) {
    const picRef = ref(storage, url);
    try {
      await deleteObject(picRef);
    } catch (error) {
      throw new Error("Couldn't delete file.");
    }
  }

  private async uploadPic(pic: File) {
    const extension = pic.name.split(".").pop();
    const name = `${Date.now()}.${extension}`;
    const path = `User/${name}`; // TODO: set file upload path appropriately e.g. tenantid/service-users/filename.jpg
    const storageRef = ref(storage, path);

    try {
      const snapshot = await uploadBytes(storageRef, pic);
      const downloadURL = await getDownloadURL(snapshot.ref);

      return downloadURL;
    } catch (error) {
      throw new Error("Failed to upload file");
    }
  }
}
