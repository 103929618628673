import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCity,
  faGavel,
  faHomeAlt,
  faPhone,
  faShareNodes,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="page-footer">
      <div className="page-footer__container uk-container uk-container-large">
        <div
          className="page-footer__content uk-grid uk-child-width-1-3@s"
          data-uk-grid
        >
          <div
            className="page-footer__content__item uk-grid-small uk-child-width-1-2"
            data-uk-grid
          >
            <div>
              <ul className="nav-links">
                <li className="nav-link--item">
                  <a href="/">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faHomeAlt}
                    />
                    Home
                  </a>
                </li>
                <li className="nav-link--item">
                  <a href="/properties">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faCity}
                    />
                    Properties
                  </a>
                </li>
                <li className="nav-link--item">
                  <a href="/raffle">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faTicketAlt}
                    />
                    Raffle
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="nav-links">
                <li className="nav-link--item">
                  <a href="/affiliates">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faShareNodes}
                    />
                    Affiliates
                  </a>
                </li>
                <li className="nav-link--item">
                  <a href="/contact-us">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faPhone}
                    />
                    Contact
                  </a>
                </li>
                <li className="nav-link--item">
                  <a href="/TCs">
                    <FontAwesomeIcon
                      className="uk-margin-small-right"
                      icon={faGavel}
                    />
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="page-footer__content__item">
            <div>
              <img src={process.env.PUBLIC_URL + "/logo.png"} alt="" />
            </div>
          </div>

          <div className="page-footer__content__item">
            <div>
              <ul className="social-links">
                <li className="social-link--item">
                  <a
                    href="https://www.facebook.com/namibiapropertyraffle/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="social-link--item">
                  <a
                    href="https://instagram.com/namibiapropertyraffle/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>

              <a className="copyrights" href="#">
                Copyrights Reserved. I dream Africa
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
