import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "../components/navigation/Navbar";
import PropertiesPage from "./PropertiesPage";
import HomePage from "./HomePage";
import RafflePage from "./RafflePage";
import AffiliatesPage from "./AffiliatesPage";
import { useAppContext } from "../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import CheckoutPage from "./CheckoutPage";
import TransactionPage from "./TransactionPage";
import ConsortiumPage from "./ConsortiumPage";
import ConsortiumReadPage from "./ConsortiumReadPage";

const Main = observer(() => {
  const { store, api } = useAppContext();
  const navigate = useNavigate();

  const signOut = () => {
    api.auth.logOut();
    navigate("/");
  };

  return (
    <div className="main-layout">
      <Navbar user={store.auth.me} signOut={signOut} />
      <Outlet />
    </div>
  );
});

const LoggedInPortalRoutes = () => {
  return (
    <Routes>
      <Route element={<Main />}>
        <Route index element={<HomePage />} />
        <Route path="properties" element={<PropertiesPage />} />
        <Route path="raffle" element={<RafflePage />} />
        <Route path="affiliates" element={<AffiliatesPage />} />
        <Route path="consortium" element={<ConsortiumPage />} />
        <Route path="consortium/:id" element={<ConsortiumReadPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
        <Route path="checkout/:qty" element={<CheckoutPage />} />
        <Route path="transaction/:id" element={<TransactionPage />} />
      </Route>
      {/* Redirects */}
      <Route path="*" element={<Navigate to="/portal" />} />
    </Routes>
  );
};

export default LoggedInPortalRoutes;
