import Model from "./Model";

export const defaultUser: IUser = {
  uid: "",
  firstName: "",
  lastName: "",
  fullName: "",
  mobilePhone: "",
  email: "",
  photoURL: null,
  userVerified: false,
  accountType: "client",
  accountEnabled: false,
  physicalAddress: "",
  country: "",
  passport: "",
  referredBy: "",
  tickets: 0,
  createdOn: Date.now(),

  // Referred tickets and balance fields
  referredTickets: 0,
  commission: 0,
  referredByCommissionRate: 5,
  paidWithdrawBalance: 0,
  pendingWithdrawBalance: 0,
};

export interface IUser {
  uid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  mobilePhone: string;
  email: string;
  photoURL: string | null;
  accountEnabled: boolean;
  physicalAddress: string;
  country: string;
  passport: string;
  referredBy: string;
  userVerified: boolean;
  accountType: "client" | "admin";
  tickets: number;
  createdOn: number;

  // Referred tickets & balance fields
  referredTickets: number;
  commission: number;
  referredByCommissionRate: number;
  paidWithdrawBalance: number;
  pendingWithdrawBalance: number;
}

export default class User extends Model<IUser>  {
  // update() {
  //   showModal(MODAL_NAMES.REGISTER_MODAL);
  //   this.store.ticket.select(this.item);
  // }

  // edit() {
  //   showModal(MODAL_NAMES.REGISTER_MODAL);
  //   this.store.ticket.select(this.item);
  // }

  // async remove() {
  //   if (!window.confirm("Delete the ticket?")) return;
  //   await this.api.ticket.delete(this.item);
  // }
}
