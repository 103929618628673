import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {
  TicketDocDefinition,
  bodyText,
  marginTopBottom,
  sectionHeader,
} from "./DocDefition";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// create pdf
const createPDF = async (docRef: any) => {
  const newWindow = window.open();
  pdfMake
    .createPdf((await TicketDocDefinition(docRef)) as any)
    .open({}, newWindow);
};


const ticketRef = (name: string, code: string, date: string) => {
  const ticketRef = [
    bodyText("Raffle Code:"),
    sectionHeader(code),
    bodyText("Name:"),
    sectionHeader(name),
    bodyText("Date of Purchase"),
    sectionHeader(date),
    marginTopBottom(),
  ]

  return ticketRef
};

const TicketPDFGen = async (
  name: string,
  code: string,
  date: string,
) => {
  // Return pdf->docRef of objectives
  const docRef = ticketRef(name, code, date);

  // create pdf
  createPDF(docRef);
};

export default TicketPDFGen;
