/* eslint-disable jsx-a11y/scope */
import swal from "sweetalert";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AffiliateModal from "../../logged-in/modals/affiliate-modal/AffiliateModal";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import Modal, { showModal } from "../../common/components/modal/Modal";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import PropertyCard from "../../common/components/property-card/PropertyCard";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import { MODAL_NAMES } from "../../common/config/constants";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import PromotionBanner from "../components/promotion-banner/PromotionBanner";
import { useAppContext } from "../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import Loading from "../../common/components/loading/Loading";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AffiliatesPage = observer(() => {
  const { api, store } = useAppContext();
  const [loadingPromoBanner, setloadingPromoBanner] = useState(false);
  const navigate = useNavigate();

  const promoBanner = store.metadata.getItemById("promo-banner");
  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  const onGetStarted = () => {
    if (!store.auth.me)
      swal({
        title: "Join affiliate program! Sign Up",
        text: "Create an account to start attracting a wide range of potential buyers and earn a commission on each ticket sold.",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else showModal(MODAL_NAMES.AFFILIATE_MODAL);
  };

  useEffect(() => {
    const load = async () => {
      setloadingPromoBanner(true);
      await api.metadata.getById("promo-banner");
      setloadingPromoBanner(false);
    };
    load();
  }, [api.metadata]);

  return (
    <ErrorBoundary>
      <div className="affiliates-page">
        <CarouselSlider>
          <SliderItem
            backroundImage={sliderPath + "/1.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Affiliates"
          />
          <SliderItem
            backroundImage={sliderPath + "/2.png"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Affiliates"
          />
          <SliderItem
            backroundImage={sliderPath + "/3.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Affiliates"
          />
          <SliderItem
            backroundImage={sliderPath + "/4.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Affiliates"
          />
          <SliderItem
            backroundImage={sliderPath + "/5.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Affiliates"
          />
        </CarouselSlider>

        <div className="uk-container uk-container-large">
          <div className="uk-margin-large">
            <SectionParagraphContent heading="Become An Affiliate">
              <Paragraph textCenter>
                We are excited to announce that we are offering you the chance
                to earn 5% on every ticket sold through an affiliates account.
                Create a profile, signing up as an affiliate and start inviting
                your family, friends and colleagues. From your affiliates
                account you can keep track of tickets sold using your affiliate
                link! <span className="hash-tag">#Own a piece of Namibia#</span>
              </Paragraph>
            </SectionParagraphContent>

            <div className="uk-text-center uk-margin-medium" data-aos="fade-up">
              <button
                className="kit-btn kit-btn-lg kit-btn-success"
                onClick={onGetStarted}
              >
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faShareNodes}
                />
                {/* Join Affiliate Program */}
                Share
              </button>
            </div>

            <div className="uk-margin-medium">
              <SectionGridContent gridClasses="uk-flex-center uk-grid-match">
                <GridCard>
                  <PropertyCard
                    sticker="First Prize"
                    location="Amani Game Farm, Khomas Region"
                    title="Small Game Farm"
                    value="Cash Value N$17M"
                    path="/properties/amani"
                  />
                </GridCard>

                <GridCard>
                  <PropertyCard
                    sticker="Second Prize"
                    location="Burg's View, Khomas Region"
                    title="Prominent Urban Property"
                    value="Cash Value N$12M"
                    path="/properties/burg-view"
                  />
                </GridCard>
              </SectionGridContent>
            </div>
          </div>
        </div>

        {!loadingPromoBanner && promoBanner ? (
          <PromotionBanner
            countries={promoBanner.asJson.countries}
            ticketsSold={promoBanner.asJson.tickets}
            closingDate={promoBanner.asJson.closingDate}
            hasContainer
          />
        ) : (
          <Loading isEllipsis />
        )}
      </div>

      <Modal modalId={MODAL_NAMES.AFFILIATE_MODAL}>
        <AffiliateModal />
      </Modal>
    </ErrorBoundary>
  );
});

export default AffiliatesPage;
