import PageHeading from "../page-heading/PageHeading";
import "./SectionGridContent.scss";

interface IParagraphProps {
  children: React.ReactNode;
  textCenter?: boolean;
}
export const GridCard = (props: IParagraphProps) => {
  const className = props.textCenter
    ? "section-content--card section-content--card--text-center"
    : "section-content--card";
  return (
    <div className={className} data-aos="fade-up">
      {props.children}
    </div>
  );
};

interface IProps {
  heading?: string;
  children: React.ReactNode; //'children' is a prop that accepts any Paragraph components';
  sectionClasses?: string;
  gridClasses?: string;
}
const SectionGridContent = (props: IProps) => {
  return (
    <div
      className={`section-content ${props.sectionClasses || ""}`}
      data-aos="fade-up"
    >
      {props.heading && <PageHeading>{props.heading}</PageHeading>}

      <div
        className={`section-content--card__grid uk-grid uk-child-width-1-2@s uk-child-width-1-3@m ${
          props.gridClasses || ""
        }`}
        data-uk-grid
      >
        {props.children}
      </div>
    </div>
  );
};

export default SectionGridContent;
