import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IBlogViewProps {
  content: string;
}
const BlogViewer: React.FC<IBlogViewProps> = (props: IBlogViewProps) => {
  const { content } = props;

  return (
    <div className="blog-viewer uk-margin">
      <ReactQuill
        id="blog-content"
        value={content}
        readOnly={true} // Set readOnly prop to make it read-only
        modules={{ toolbar: false }} // Remove the toolbar by setting it to false
        theme="snow"
      />
    </div>
  );
};

export default BlogViewer;
