import Store from "./Store";
import AppStore from "./AppStore";
import { runInAction } from "mobx";
import Blog, { IBlog } from "../models/Blog";

export default class BlogStore extends Store<IBlog, Blog> {
  items = new Map<string, Blog>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IBlog[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Blog(this.store, item))
      );
    });
  }
}
