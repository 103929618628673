import Blog, { IBlog } from "../models/Blog";
import Consortium, { IConsortium } from "../models/Consortium";
import Ticket, { ITicket } from "../models/Ticket";
import User, { IUser } from "../models/User";
import Withdrawal, { IWithdrawal } from "../models/Withdrawal";

// Sort user
export const sortUser = (a: User, b: User) => a.asJson.fullName.localeCompare(b.asJson.fullName)
export const sortIUser = (a: IUser, b: IUser) => a.fullName.localeCompare(b.fullName)

// Sort tickets
export const sortTicket = (a: Ticket, b: Ticket) => b.asJson.createdOn - a.asJson.createdOn
export const sortITicket = (a: ITicket, b: ITicket) => b.createdOn - a.createdOn


// Sort withdraws
export const sortWithdraw = (a: Withdrawal, b: Withdrawal) => b.asJson.createdOn - a.asJson.createdOn
export const sortIWithdraw = (a: IWithdrawal, b: IWithdrawal) => b.createdOn - a.createdOn

// Sort consortium
export const sortConsortium = (a: Consortium, b: Consortium) => a.asJson.name.localeCompare(b.asJson.name)
export const sortIConsortium = (a: IConsortium, b: IConsortium) => a.name.localeCompare(b.name)

// Sort blogs
export const sortBlog = (a: Blog, b: Blog) => b.asJson.createdOn - a.asJson.createdOn
export const sortIBlog = (a: IBlog, b: IBlog) => b.createdOn - a.createdOn

