import React from "react";

const PurchaseOnHold = () => {
  return (
    <div>
      <div className="order-summary-card">
        <div className="uk-padding">
          <div className="info-icon">
            <img src={process.env.PUBLIC_URL + "/icons/3223311.png"} alt="" />
          </div>

          <h3 className="title uk-modal-title">Ticket Purchase On Hold!</h3>

          <p className="content">
            Ticket purchase is put on hold since the Lotteries Board of Namibia
            issued a media release stating that the Multi-Million online Real
            Estate Raffle (Namibia Property Raffle Project) is not approved and
            is regarded as an illegal lottery. The Lotteries Board also
            mentioned that they are taking the necessary measures as per Part
            10, sections 74 to 77 of the Lotteries Act, and they will inform the
            public accordingly.
          </p>
          <a
            href="https://namibiapropertyraffle.com/news/32aXGxmPoQ4cwF8b6l5W"
            className="kit-btn kit-btn-primary"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOnHold;
