import Store from "./Store";
import { runInAction } from "mobx";
import AppStore from "./AppStore";
import Metadata, { IMetadata } from "../models/Metadata";

export default class MetadataStore extends Store<IMetadata, Metadata> {
  items = new Map<string, Metadata>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IMetadata[]) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Metadata(this.store, item))
      );
    });
  }
}
