import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faToolbox,
  faHomeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import User from "../../models/User";
import Dropdown from "../dropdown/Dropdown";

interface INavItemProps {
  name: string | React.ReactNode;
  path: string;
  children?: React.ReactNode;
}
const NavItem = (props: INavItemProps) => {
  return (
    <li className="nav-item">
      <NavLink to={props.path} className="nav-link">
        {props.name}
      </NavLink>

      {props.children}
    </li>
  );
};

interface IProps {
  user: User | null;
  signOut: () => void;
}
const AccountComp = (props: IProps) => {
  const { user, signOut } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPortal = pathname.includes("portal");

  const onGoToPortal = () => navigate("/portal");
  const onGoToWebsite = () => navigate("/");

  if (user) {
    const photoURL = user.asJson.photoURL
      ? user.asJson.photoURL
      : process.env.PUBLIC_URL + "/icons/1144709.png";

    return (
      <li className="nav-item nav-item--account">
        <button className="profile" title="More">
          <span className="account-name">
            {user.asJson.firstName} {user.asJson.lastName}
          </span>
          <img src={photoURL} alt="" />
          <span data-uk-icon="icon: caret-down; ratio: 1.2"></span>
        </button>
        <Dropdown pos="bottom-right">
          {isPortal ? (
            <li className="kit-dropdown-item">
              <button className="kit-dropdown-btn" onClick={onGoToWebsite}>
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faHomeAlt}
                />
                Back to Website
              </button>
            </li>
          ) : (
            <li className="kit-dropdown-item">
              <button className="kit-dropdown-btn" onClick={onGoToPortal}>
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faToolbox}
                />
                My Portal
              </button>
            </li>
          )}
          {/* <li className="kit-dropdown-item">
            <button className="kit-dropdown-btn">
              <FontAwesomeIcon
                className="uk-margin-small-right"
                icon={faUser}
              />
              Profile
            </button>
          </li> */}
          <li className="kit-dropdown-item">
            <button className="kit-dropdown-btn" onClick={signOut}>
              <FontAwesomeIcon
                className="uk-margin-small-right"
                icon={faArrowRightFromBracket}
              />
              Sign out
            </button>
          </li>
        </Dropdown>
      </li>
    );
  }

  return (
    <>
      <NavItem
        name={
          <>
            <FontAwesomeIcon
              className="uk-margin-small-right"
              icon={faArrowRightToBracket}
            />
            Sign In
          </>
        }
        path="sign-in"
      />

      <NavItem name={"Sign Up"} path="sign-up" />
    </>
  );
};

export default AccountComp;
