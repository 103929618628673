import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import BlogViewer from "../components/blogs/BlogEditor";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppContext } from "../../common/hooks/useContext";
import { defaultBlog } from "../../common/models/Blog";
import Loading from "../../common/components/loading/Loading";
import { observer } from "mobx-react-lite";
import "./Style.scss";

const BlogsReadPage = observer(() => {
  const { id } = useParams<{ id: string }>();

  // store and api
  const { store, api } = useAppContext();

  // initialize states
  const [loading, setLoading] = useState(false);

  // retrieve blog from store.
  const blog = store.blog.getItemById(id!)?.asJson || defaultBlog;

  // load data from db.
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (id) await api.blog.getById(id);
      setLoading(false);
    };

    // load data from db if editting.
    load();
  }, [api.blog, id]);

  if (loading) return <Loading fullHeight isEllipsis></Loading>;

  return (
    <ErrorBoundary>
      <div className="blogs-read-page">
        <div className="uk-section uk-section-small uk-section-default">
          <div className="uk-container uk-container-large">
            <div className="blog-header">
              <h1 className="blog-title">{blog.title}</h1>
            </div>

            <BlogViewer content={blog.content} />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default BlogsReadPage;
