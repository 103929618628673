import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../hooks/useContext";
import { IUser } from "../../models/User";

interface IProps {
  children: JSX.Element;
}
export const PrivateRoute = observer((props: IProps) => {
  const { children } = props;
  const { store } = useAppContext();
  const { pathname } = useLocation();
  const state = { from: pathname };

  const user = store.auth.meJson as IUser;

  if (!user) return <Navigate to="/" state={state} />; // Route to home page
  if (user.accountType === "client" || user.accountType === "admin")
    return children;
  return <Navigate to="/" />; // Route to home page if user is logged in, don't keep state to avoid loop error.
});
