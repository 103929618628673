import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowUpRightFromSquare,
  faCity,
  faHomeAlt,
  faMoneyBillTransfer,
  faPeopleGroup,
  faPeoplePulling,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface IQuickActionCardProps {
  icon: IconProp;
  path: string;
  children: ReactNode;
}
const QuickActionCard = (props: IQuickActionCardProps) => {
  const { icon, path, children } = props;
  const navigate = useNavigate();
  const onClick = () => navigate(path);

  return (
    <div
      className="quick-action-card kit-card kit-card-default"
      data-aos="fade-up"
      onClick={onClick}
    >
      <div className="icon-show-onhover">
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </div>
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      {children}
    </div>
  );
};

const Header = () => {
  const quickActions = [
    {
      path: "/",
      icon: faHomeAlt,
      name: "Go Back to Website",
      description: "Go back to home page",
    },
    {
      path: "/portal/properties",
      icon: faCity,
      name: "View Properties",
      description: "View the properties for grabs",
    },
    {
      path: "/portal/raffle",
      icon: faTicket,
      name: "Raffle & Tickets",
      description: "Buy tickets",
    },
    {
      path: "/portal/affiliates",
      icon: faPeoplePulling,
      name: "Affiliate Program",
      description: "Share with friends",
    },
    {
      path: "/portal/consortium",
      icon: faPeopleGroup,
      name: "Consortium Program",
      description: "Buy as a group",
    },
    // {
    //   path: "/admin/withdrawals",
    //   icon: faMoneyBillTransfer,
    //   name: "Manage Withdrawals",
    //   description: "Manage all the withdrawals.",
    // },
  ];

  return (
    <div className="kit-header">
      <div
        className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
        data-uk-grid
      >
        {quickActions.map((quickAction) => (
          <div key={quickAction.name}>
            <QuickActionCard icon={quickAction.icon} path={quickAction.path}>
              <div className="content">
                <h3 className="title">{quickAction.name}</h3>
                <p className="description">{quickAction.description}</p>
              </div>
            </QuickActionCard>
          </div>
        ))}
      </div>
    </div>
  );
};

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="uk-section uk-section-small uk-section-muted">
        <div className="uk-container uk-container-large">
          <Header />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
