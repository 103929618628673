import { MainApp } from "../models/App";
import AffiliateStore from "./AffiliateStore";
import AuthStore from "./AuthStore";
import TicketStore from "./TicketStore";
import MetadataStore from "./MetadataStore";
import UserStore from "./UserStore";
import TransactionStore from "./TransactionStore";
import WithdrawStore from "./WithdrawStore";
import ConsortiumStore from "./ConsortiumStore";
import AdvertStore from "./AdvertStore";
import BlogStore from "./BlogStore";

export default class AppStore {
  app: MainApp;
  auth = new AuthStore(this); // auth store
  user = new UserStore(this); // user store
  affiliate = new AffiliateStore(this); // affiliate store
  metadata = new MetadataStore(this); // metadata store
  ticket = new TicketStore(this); // ticket store
  consortium = new ConsortiumStore(this); // consortium store
  withdraw = new WithdrawStore(this); // withdraw store
  transaction = new TransactionStore(this); // transaction store
  advert = new AdvertStore(this);
  blog = new BlogStore(this);


  constructor(app: MainApp) {
    this.app = app;
  }
}
