import { useAppContext } from "../../../common/hooks/useContext";
import Withdrawal from "../../../common/models/Withdrawal";
import { observer } from "mobx-react-lite";
import NoData from "../../../common/components/no-data/NoData";
import { faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortWithdraw } from "../../../common/utils/Sort";
import { useMemo } from "react";
import { round } from "../../../common/utils/Math";
import "./WithdrawHistory.scss";

interface ITableRowProps {
  record: Withdrawal;
}
const TableRow = (props: ITableRowProps) => {
  const { createdOn, status, amount, bankAccountNumber } = props.record.asJson;
  const date = new Date(createdOn).toLocaleDateString();

  const onCancel = async () => {
    try {
      await props.record.cancel();
    } catch (error) {}
  };

  const onDownload = async () => {
    try {
      await props.record.invoice();
    } catch (error) {}
  };

  return (
    <tr className="kit-table-row">
      <td data-label="Date">{date}</td>
      <td data-label="Status" className={"wstatus wstatus-" + status}>
        {status}
      </td>
      <td data-label="Account #">{bankAccountNumber || "-"}</td>
      <td data-label="Amount">N${round(Number(amount) / 100)}</td>

      <td data-label="Actions" className="uk-text-right">
        {status === "pending" && (
          <button
            className="kit-btn kit-btn-sm kit-btn-danger"
            title="Cancel"
            onClick={onCancel}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {status === "paid" && (
          <button
            className="kit-btn-text kit-btn-sm"
            title="Invoice"
            onClick={onDownload}
          >
            <FontAwesomeIcon icon={faDownload} size="lg" />
          </button>
        )}
      </td>
    </tr>
  );
};

const WithdrawHistoryModal = observer(() => {
  const { store } = useAppContext();

  // List all withdrawals.
  const records = useMemo(
    () => store.withdraw.all.sort(sortWithdraw),
    [store.withdraw.all]
  );

  return (
    <div className="withdraw-history-modal uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <h3 className="title uk-modal-title">Withdraw History</h3>

      <div className="dialog-content uk-position-relative">
        {records.length === 0 && <NoData>No withdrawal history</NoData>}

        {records.length !== 0 && (
          <table className="kit-table">
            <thead className="kit-table-header">
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Account #</th>
                <th>Amount</th>
                <th className="uk-text-right" title="Actions"></th>
              </tr>
            </thead>

            <tbody>
              {records.map((record) => (
                <TableRow key={record.asJson.id} record={record} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
});

export default WithdrawHistoryModal;
