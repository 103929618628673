import React from "react";
import "./PageHeading.scss";

interface IProps {
  children: string | React.ReactNode;
}
const PageHeading = (props: IProps) => {
  const { children } = props;

  // check children type
  if (typeof children === "string")
    return (
      <div className="page-heading--section">
        <h1 className="page-heading">{props.children}</h1>
      </div>
    );

  return (
    <div className="page-grid-heading--section">
      <div
        className="uk-grid-large uk-child-width-1-2@s uk-grid-match uk-margin-medium"
        data-uk-grid
      >
        {props.children}
      </div>
    </div>
  );
};

export default PageHeading;
