import ReactDOM from "react-dom";
declare const UIkit: any;

export const hideModal = (id: string) => {
  const element = document.querySelector(`#${id}`);
  UIkit.modal(element).hide();
};

export const showModal = (id: string) => {
  const element = document.querySelector(`#${id}`);
  UIkit.modal(element).show();
};

interface Props {
  modalId: string;
  cssClass?: string;
  children: any;
}
const Modal = (props: Props) => {
  const { modalId, cssClass, children } = props;
  const newClass = cssClass ? cssClass : "";

  const modal = (
    <div
      id={modalId}
      className={`kit-modal ${newClass}`}
      data-uk-modal
      data-bg-close={false}
    >
      {children}
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default Modal;
