import {
  query,
  collection,
  onSnapshot,
  Unsubscribe,
} from "@firebase/firestore";
import { db } from "../config/firebase-config";
import { IAdvert } from "../models/Advert";
import AppStore from "../stores/AppStore";
import { where } from "firebase/firestore";
import AppApi from "./AppApi";

export default class AdvertApi {
  private path: string = "adverts";
  private lastVisible: any | null = null;


  constructor(private api: AppApi, private store: AppStore) { }

  // path setter
  setPath(path: string) {
    this.path = path;
  }


  async getAll() {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    // this.store.advert.removeAll();

    // create the query
    const $query = query(collection(db, path), where("isActive", "==", true)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IAdvert[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ id: doc.id, ...doc.data() } as IAdvert);
          });

          this.store.advert.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }
}


