import { makeObservable, runInAction } from "mobx";
import AppStore from "./AppStore";

export default abstract class Store<Type, Model> {
  protected store: AppStore;
  items = new Map<string, Model>(); // stores all the items in the store
  selected: Type | null = null; // used for modals, to select the item to edit.
  loading: boolean = true; // loading items

  constructor(store: AppStore) {
    this.store = store;

    makeObservable(this, {
      items: true,
      selected: true,
      loading: true,
    });
  }

  load(items: Type[] = []) { }

  remove(id: string) {
    runInAction(() => {
      if (this.items.has(id)) this.items.delete(id);
    });
  }

  removeAll() {
    runInAction(() => {
      this.items.clear();
    });
  }

  getItemById(id: string) {
    return this.items.get(id);
  }

  get all() {
    return Array.from(this.items.values());
  }

  get isEmpty() {
    return this.items.size === 0;
  }

  select(item: Type) {
    runInAction(() => {
      this.selected = item;
    });
  }

  clearSelected() {
    runInAction(() => {
      this.selected = null;
    });
  }
}
