import { makeAutoObservable } from "mobx";

export interface ISnackbar {
  id: number;
  message: string;
  type: "primary" | "success" | "warning" | "danger" | "default";
  children?: any;
  timeoutInMs?: number;
}

export default class Snackbar {
  private snackbar: ISnackbar;

  constructor(snackbar: ISnackbar) {
    makeAutoObservable(this);
    this.snackbar = snackbar;
  }

  get asJson(): ISnackbar {
    return this.snackbar;
  }
}
