import AppStore from "../stores/AppStore";
import AuthApi from "./AuthApi";
import TicketApi from "./TicketApi";
import MailApi from "./MailApi";
import UserApi from "./UserApi";
import AffiliateApi from "./AffiliateApi";
import CheckoutApi from "./CheckoutApi";
import MetadataApi from "./MetadataApi";
import TransactionApi from "./TransactionApi";
import WithdrawApi from "./WithdrawApi";
import ConsortiumApi from "./ConsortiumApi";
import AdvertApi from "./AdvertApi";
import BlogApi from "./BlogApi";

const MAIL_API = "https://namibiapropertyraffle.com/api/mail.php?";

export default class AppApi {
  auth: AuthApi;
  user: UserApi;
  affiliate: AffiliateApi;
  metadata: MetadataApi;
  checkout: CheckoutApi;
  ticket: TicketApi;
  withdraw: WithdrawApi;
  transaction: TransactionApi;
  consortium: ConsortiumApi;
  mail: MailApi;
  advert: AdvertApi;
  blog: BlogApi;



  constructor(store: AppStore) {
    this.auth = new AuthApi(this, store);
    this.user = new UserApi(this, store);
    this.affiliate = new AffiliateApi(this, store);
    this.metadata = new MetadataApi(this, store);
    this.checkout = new CheckoutApi(this, store);
    this.ticket = new TicketApi(this, store);
    this.advert = new AdvertApi(this, store);
    this.blog = new BlogApi(this, store);
    this.withdraw = new WithdrawApi(this, store);
    this.transaction = new TransactionApi(this, store);
    this.consortium = new ConsortiumApi(this, store);
    this.mail = new MailApi(this, store, MAIL_API);
  }
}
