import { IPurchaseType } from "./Consortium";
import { IConsortiumMember } from "./ConsortiumMember";
import Model from "./Model";

export const defaultTransaction: ITransaction = {
  id: "",
  // User properties
  uid: "",
  fullName: "",
  referredBy: "",
  country: "",
  // Consortium properties
  consortiumId: "",
  consortiumName: "",
  consortiumMembers: [],
  consortiumMembersUids: [],
  purchaseType: "individual",
  // Other properties
  countryCode: "NAM",
  unitPrice: "",
  amount: "",
  quantity: "1",
  referredByCommissionRate: 5,
  currency: "NAD",
  locale: 'en',
  isPaid: false,
  isVerified: false,
  isCancelled: false,
  createdOn: 0,
  result: -1,
  transactionStatus: 0,
  resultDesc: "",
  authCode: "",
}

export interface ITransaction {
  id: string;
  uid: string;
  fullName: string;
  referredBy: string;
  countryCode: string;
  country: string;
  quantity: string;
  unitPrice: string;
  amount: string;
  referredByCommissionRate: number;
  currency: string;
  locale: string;
  isPaid: boolean;
  isVerified: boolean;
  isCancelled: boolean;
  createdOn: number;
  transactionStatus: number;
  result: number; // Contains the paygate transaction result code
  resultDesc: string; // Contains the paygate error msg.
  authCode: string;

  // Consortium properties
  consortiumId: string;
  consortiumName: string;
  consortiumMembers: IConsortiumMember[];
  consortiumMembersUids: string[];
  purchaseType: IPurchaseType;
}

export default class Transaction extends Model<ITransaction> {
}
