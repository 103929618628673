export const generateTransactionReference = () => {
    return ((Math.random() + 1).toString(36).substring(4)) + Date.now()
}

export const ticketProcessor = (id: string) => {
    const firstPart = id.substring(0, 2);
    const secondPart = id.substring(2, 5);
    const thirdPart = id.substring(5);

    return `${firstPart} - ${secondPart} - ${thirdPart}`;
};