import Model from "./Model";

export const defaultMetadata: IMetadata = {
    id: ""
};

export interface IMetadata {
    id: string;
    [key: string]: any;
}

export default class Metadata extends Model<IMetadata>  {

}
