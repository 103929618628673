import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import "./Style.scss";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import DocumentCard from "../../common/components/document-card/DocumentCard";
import { useEffect, useState } from "react";
import { useAppContext } from "../../common/hooks/useContext";
import Loading from "../../common/components/loading/Loading";
import { observer } from "mobx-react-lite";

const TCPage = observer(() => {
  const { api, store } = useAppContext();
  const [loadingTCDocs, setLoadingTCDocs] = useState(false);

  const tcDocuments = store.metadata.getItemById("tc-documents");
  const sliderPath = `${process.env.PUBLIC_URL}/assets/home`;

  useEffect(() => {
    const load = async () => {
      setLoadingTCDocs(true);
      await api.metadata.getById("tc-documents");
      setLoadingTCDocs(false);
    };
    load();
  }, [api.metadata]);

  return (
    <div className="contact-us-page">
      <CarouselSlider>
        <SliderItem
          backroundImage={sliderPath + "/1.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Terms & Conditions"
        />
        <SliderItem
          backroundImage={sliderPath + "/2.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Terms & Conditions"
        />
        <SliderItem
          backroundImage={sliderPath + "/3.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Terms & Conditions"
        />
        <SliderItem
          backroundImage={sliderPath + "/4.png"}
          logo={process.env.PUBLIC_URL + "/logo-white.png"}
          caption="Terms & Conditions"
        />
      </CarouselSlider>

      <div className="uk-container uk-container-large">
        <div className="uk-margin-large">
          <SectionParagraphContent heading="Download Documents">
            <Paragraph textCenter>
              Thank you for considering downloading our terms and conditions
              document. We highly recommend that you take the time to review
              this document carefully, as it contains important information
              about your rights and obligations as a user of our services. By
              downloading and using our services, you agree to be bound by the
              terms and conditions outlined in this document.
            </Paragraph>

            <Paragraph textCenter additionalClass="uk-margin">
              Please take a moment to review the document and familiarize
              yourself with its contents. If you have any questions or concerns,
              don't hesitate to reach out to us for clarification. Thank you for
              choosing to use our services, and we hope you find them useful and
              enjoyable.
            </Paragraph>
          </SectionParagraphContent>
        </div>

        {!loadingTCDocs && tcDocuments ? (
          <div className="uk-margin-large">
            <SectionGridContent gridClasses="uk-flex-center uk-grid-match">
              {tcDocuments.asJson.docs.map((doc: any, index: number) => (
                <GridCard key={index}>
                  <DocumentCard
                    docType={doc.docType}
                    title={doc.title}
                    path={doc.url}
                  />
                </GridCard>
              ))}
            </SectionGridContent>
          </div>
        ) : (
          <Loading isEllipsis />
        )}
      </div>
    </div>
  );
});

export default TCPage;
