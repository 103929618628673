import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../common/hooks/useContext";
import { observer } from "mobx-react-lite";
import Blog from "../../../common/models/Blog";
import Loading from "../../../common/components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { sortBlog } from "../../../common/utils/Sort";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./blog-style.scss";

interface IBlogProps {
  blog: Blog;
}
const BlogItem = (props: IBlogProps) => {
  const blog = props.blog;
  const { id, title, brief } = blog.asJson;

  // Initialize navigator item
  const navigate = useNavigate();

  // open/view blog
  const onView = () => navigate(id);

  return (
    <div className="blog-item kit-card-default" onClick={onView}>
      <div className="blog-item--content kit-card">
        <h5 className="title">{title}</h5>
        <p className="brief">{brief}</p>
        <button className="kit-btn-text-only">
          Read More <FontAwesomeIcon className="icon" icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

const Blogs = observer(() => {
  const { store, api } = useAppContext();

  const blogs = useMemo(() => store.blog.all.sort(sortBlog), [store.blog.all]);

  // Initialize states
  const [loading, setLoading] = useState(false);

  // Load the blogs
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await api.blog.getAll();
      setLoading(false);
    };
    load();
  }, [api.blog]);

  if (loading) return <Loading fullHeight isEllipsis />;

  return (
    <div className="blogs uk-margin" data-aos="fade-up">
      <div className="uk-grid uk-child-width-1-2@m uk-grid-match" data-uk-grid>
        {blogs.map((b) => (
          <div key={b.asJson.id}>
            <BlogItem blog={b} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default Blogs;
