import Model from "./Model";

export const defaultBlog: IBlog = {
  id: "",
  title: "",
  brief: "",
  content: "",
  author: "",
  createdOn: Date.now()
};


export interface IBlog {
  id: string;
  title: string;
  brief: string;
  author: string;
  content: string;
  createdOn: number;
}


export default class Blog extends Model<IBlog> {

}
