import {
  faBed,
  faCreditCard,
  faLocationDot,
  faMap,
  faMapPin,
  faParking,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import SectionParagraphContent, {
  Paragraph,
} from "../../common/components/page-heading/SectionParagraphContent";
import SectionGridContent, {
  GridCard,
} from "../../common/components/section-grid/SectionGridContent";
import CarouselSlider, {
  SliderItem,
} from "../components/carousel-slider/CarouselSlider";
import { observer } from "mobx-react-lite";
import { useAppContext } from "../../common/hooks/useContext";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./Style.scss";

interface IGridImageProps {
  name: string;
  ext?: "jpg" | "png" | "gif";
  caption?: string;
}
const GridImage = (props: IGridImageProps) => {
  // extract props
  const { name, ext = "jpg", caption = "" } = props;
  const path = process.env.PUBLIC_URL + "/assets/property/burgs";
  const src = path + "/" + name + "." + ext;

  return (
    <a className="uk-inline" href={src} data-caption={caption}>
      <GridCard textCenter>
        <div className="gallery-image">
          <div className="gallery-image__border-bottom-left"></div>
          <div className="gallery-image__border-right-top"></div>
          <img src={src} alt={caption} />
        </div>
      </GridCard>
    </a>
  );
};

const ViewBurgDestinationPage = observer(() => {
  const { store } = useAppContext();
  const navigate = useNavigate();

  const sliderPath = process.env.PUBLIC_URL + "/assets/home";

  const onBuy = () => {
    if (!store.auth.me)
      swal({
        title: "Sign up!",
        text: "Create an account to start buying tickets!",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else navigate("/portal/checkout");
  };

  return (
    <ErrorBoundary>
      <div className="view-destination-page">
        <CarouselSlider>
          <SliderItem
            backroundImage={sliderPath + "/2.png"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Prizes"
          />
          <SliderItem
            backroundImage={sliderPath + "/4.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Prizes"
          />
          <SliderItem
            backroundImage={sliderPath + "/6.jpg"}
            logo={process.env.PUBLIC_URL + "/logo-white.png"}
            caption="Prizes"
          />
        </CarouselSlider>

        <div className="uk-container uk-container-large">
          <div className="uk-margin-large">
            <SectionParagraphContent
              heading={
                <Fragment>
                  <div>
                    <div className="page-heading-grid-card">
                      <h2 className="p-location">Windhoek, Khomas Region</h2>
                      <h1 className="p-title">Burg's View Windhoek (12M)</h1>
                      <h2 className="p-location uk-margin-small-top">
                        Cash Value N$12,000,000
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div className="page-heading-grid-card">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/tickets/c.png"}
                        alt="Raffle Ticket"
                      />
                    </div>
                  </div>
                </Fragment>
              }
            >
              <Paragraph textCenter>
                PROPERTY DETAIL:
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faLocationDot}
                />
                Deeds Registry: Windhoek Property Type: Boutique Hotel Erf
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faMapPin}
                />
                Number: Erf 8021 - WindhoekDistrict: Khomas - Windhoek
                Registration Division:“K”
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faMap}
                />
                Extent:1132.80m² Local Authority: City of Windhoek Zoning:
                Residential (Business – approved by Windhoek City Council)
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faBed}
                />
                Unfurnished 10 Luxury On-suite rooms 1 Managers flat{" "}
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faParking}
                />
                10 Parking bays on property
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faParking}
                />
                8 outside Reception
                <FontAwesomeIcon
                  className="uk-margin-left uk-margin-small-right"
                  icon={faPersonChalkboard}
                />
                1 Boardroom Restaurant Area with kitchen. Sundowner Deck
              </Paragraph>
            </SectionParagraphContent>

            <div className="uk-text-center uk-margin-medium" data-aos="fade-up">
              <button
                className="kit-btn kit-btn-lg kit-btn-success"
                onClick={onBuy}
              >
                <FontAwesomeIcon
                  className="uk-margin-small-right"
                  icon={faCreditCard}
                />
                Buy Now
              </button>

              <div className="uk-margin-medium uk-text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/background/PayGate-Card-Brand-Logos-PayGate.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="uk-margin-large" data-uk-lightbox="animation: slide">
            <SectionGridContent>
              <GridImage name="1" caption="Caption 1" />
              <GridImage name="2" caption="Caption 2" />
              <GridImage name="3" caption="Caption 3" />
              <GridImage name="4" caption="Caption 4" />
              <GridImage name="5" caption="Caption 5" />
              <GridImage name="6" caption="Caption 6" />
              <GridImage name="7" caption="Caption 7" />
              <GridImage name="8" ext="png" caption="Caption 8" />
            </SectionGridContent>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default ViewBurgDestinationPage;
