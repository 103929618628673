import React, { Fragment, ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import OffcanvasNavbar from "./OffcanvasNavbar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorBoundary from "../../../common/components/error-boundary/ErrorBoundary";
import User from "../../../common/models/User";
import AccountComp from "../../../common/components/account-comp/AccountComp";
import { CENTER_NAV_ITEMS, RIGHT_NAV_ITEMS } from "./Constant";
import "./Navbar.scss";

interface INavItemProps {
  name: string | React.ReactNode;
  path: string;
  children?: React.ReactNode;
}
const NavItem = (props: INavItemProps) => {
  return (
    <li className="nav-item">
      <NavLink to={"/portal/" + props.path} className="nav-link">
        {props.name}
      </NavLink>

      {props.children}
    </li>
  );
};

const CenterNavItems = () => {
  return (
    <ul className="nav-item__list uk-navbar-nav">
      {CENTER_NAV_ITEMS.map((navitem) => {
        return (
          <NavItem name={navitem.name} path={navitem.path} key={navitem.id}>
            {navitem.subNavitems && (
              <div className="nav-item--dropdown uk-navbar-dropdown">
                <ul className="nav-item--dropdown--list uk-nav uk-navbar-dropdown-nav">
                  {navitem.subNavitems.map((subnavitem) => (
                    <li key={subnavitem.id}>
                      <NavLink to={subnavitem.path} className="nav-link">
                        {subnavitem.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </NavItem>
        );
      })}
    </ul>
  );
};

interface IRightNavItemsProps {
  children?: ReactNode;
}
const RightNavItems = (props: IRightNavItemsProps) => {
  return (
    <ul className="nav-item__list uk-navbar-nav">
      {RIGHT_NAV_ITEMS.map((navitem) => (
        <NavItem
          key={navitem.id}
          name={<>{navitem.icon && <FontAwesomeIcon icon={navitem.icon} />}</>}
          path={navitem.path}
        />
      ))}
      {props.children}
    </ul>
  );
};

interface IProps {
  user: User | null;
  signOut: () => void;
}
const Navbar = (props: IProps) => {
  const { user, signOut } = props;
  const [show, setShow] = useState(false);

  const onToggle = () => setShow(!show);

  return (
    <ErrorBoundary>
      <div
        className="navbar"
        uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
      >
        <nav className="navbar__main uk-navbar-container">
          <div className="uk-container uk-container-large">
            <div className="navbar-body uk-navbar" data-uk-navbar>
              <div className="uk-navbar-left uk-hidden@m">
                <button
                  className="toggle-navbar-button"
                  type="button"
                  onClick={onToggle}
                >
                  <FontAwesomeIcon icon={faBars} size="2x" />
                </button>
              </div>

              <div className="uk-navbar-center uk-padding-small uk-hidden@m">
                <NavLink to={"/"} id="nav-brand">
                  <img src={process.env.PUBLIC_URL + "/logo.png"} alt="" />
                </NavLink>
              </div>

              <div className="uk-navbar-center uk-visible@m">
                <CenterNavItems />
              </div>

              <div className="uk-navbar-right uk-visible@s">
                <RightNavItems>
                  <AccountComp user={user} signOut={signOut} />
                </RightNavItems>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        className={"navbar__off-canvas-parent" + (show ? " show" : "")}
        onClick={onToggle}
      >
        <OffcanvasNavbar user={user} signOut={signOut} />
      </div>
    </ErrorBoundary>
  );
};

export default Navbar;
