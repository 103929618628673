import React from "react";
import { useNavigate } from "react-router-dom";
import "./PropertyCard.scss";

interface IProps {
  sticker?: string;
  location: string;
  title: string;
  value?: string;
  path?: string;
}
const PropertyCard = (props: IProps) => {
  const navigate = useNavigate();

  const onSelect = () => {
    const path = props.path;
    if (!path) return;
    navigate(path);
  };

  return (
    <button className="property-card" onClick={onSelect}>
      {props.sticker && (
        <div className="property-card--sticker">
          <p>{props.sticker}</p>
        </div>
      )}

      <h2 className="property-card--location">{props.location}</h2>
      <h1 className="property-card--title">{props.title}</h1>
      <h1 className="property-card--value">{props.value}</h1>

      <div className="controls"></div>
    </button>
  );
};

export default PropertyCard;
