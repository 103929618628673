import React from "react";
import "./PromotionBanner.scss";
import ErrorBoundary from "../../../common/components/error-boundary/ErrorBoundary";

interface IProps {
  backgroundImg?: string;
  hasContainer?: true;
  countries?: any;
  ticketsSold?: number;
  closingDate?: string;
}
const PromotionBanner = (props: IProps) => {
  const { backgroundImg, hasContainer, closingDate } = props;

  // const countriesArr = Object.entries(countries);
  // const noOfCountries = countriesArr.reduce((acc: number, value: any) => {
  //   return value[1] > 0 ? acc + 1 : acc;
  // }, 0);

  const containerClassName = hasContainer
    ? "uk-container uk-container-large"
    : "";

  const style = {
    backgroundImage: `url(${backgroundImg})`,
  };

  return (
    <ErrorBoundary>
      <div
        className="promotion-banner uk-margin-large"
        style={style}
        data-aos="fade-up"
      >
        <div className={containerClassName}>
          {backgroundImg && <div className="promotion-banner--overlay"></div>}
          <div className="uk-grid uk-grid-match">
            {/* <div className="uk-grid uk-child-width-1-3@m uk-grid-match"> // Removed to fit "POSTPONED UNTIL FURTHER NOTICE" */}
            {/* <div>
            <div className="promotion-column">
              <p className="promotion-column--title">Countries</p>
              <p className="promotion-column--value">{noOfCountries}</p>
            </div>
          </div>

          <div>
            <div className="promotion-column">
              <p className="promotion-column--title">Tickets sold</p>
              <p className="promotion-column--value">{ticketsSold}</p>
            </div>
          </div> */}

            <div>
              <div className="promotion-column">
                <p className="promotion-column--title">Closing Date</p>
                <p className="promotion-column--value">{closingDate}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default PromotionBanner;
