/* eslint-disable jsx-a11y/scope */
import { faPlus, faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useMemo, useState } from "react";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import Loading from "../../common/components/loading/Loading";
import Modal, { showModal } from "../../common/components/modal/Modal";
import NoData from "../../common/components/no-data/NoData";
import { MODAL_NAMES } from "../../common/config/constants";
import { useAppContext } from "../../common/hooks/useContext";
import User from "../../common/models/User";
import { sortConsortium, sortUser } from "../../common/utils/Sort";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ConsortiumModal from "../modals/consortium-modal/ConsortiumModal";
import "./Style.scss";
import Consortium from "../../common/models/Consortium";
import { round } from "../../common/utils/Math";

// interface IBuyTicketCardProps {
//   onBuy: () => void;
// }
// const BuyTicketCard = (props: IBuyTicketCardProps) => {
//   const { onBuy } = props;

//   return (
//     <div
//       className="buy-ticket-card kit-card kit-card-sm kit-card-default"
//       data-aos="fade-up"
//     >
//       <div className="ticket--img-container">
//         <img src={process.env.PUBLIC_URL + "/assets/tickets/c.png"} alt="" />
//       </div>
//       <div className="footer">
//         <button onClick={onBuy}>
//           <FontAwesomeIcon icon={faTicket} /> Buy Tickets
//         </button>
//       </div>
//     </div>
//   );
// };

interface IDashboardCardProps {
  type?: "positive" | "neutral";
  children: ReactNode;
  noIcon?: boolean;
}
const DashboardCard = (props: IDashboardCardProps) => {
  const { type = "neutral", children, noIcon } = props;

  const icon =
    type === "positive"
      ? process.env.PUBLIC_URL + "/icons/1332082.png"
      : process.env.PUBLIC_URL + "/icons/5044045.png";

  return (
    <div
      className={`dashboard-card dashboard-card--${type} kit-card kit-card-default`}
      data-aos="fade-up"
    >
      {children}
      {!noIcon && (
        <div className="icon">
          <img src={icon} alt="" />
        </div>
      )}
    </div>
  );
};

interface IHeaderProps {
  // onBuy: () => void;
  children: ReactNode;
}
const Header = (props: IHeaderProps) => {
  const { children } = props;
  return (
    <div className="kit-header">
      <div
        className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
        data-uk-grid
      >
        {children}
        {/* <div>
          <BuyTicketCard onBuy={onBuy} />
        </div> */}
      </div>
    </div>
  );
};

const TableHeader = () => {
  return (
    <thead>
      <tr className="kit-table-header" data-aos="fade-up">
        <th scope="col">Name</th>
        <th scope="col">Identifier</th>
        <th scope="col">Date</th>
        <th scope="col">Members</th>
        <th className="uk-text-center" scope="col">
          Tickets
        </th>
        <th className="uk-text-right" scope="col">
          Tickets (N$)
        </th>
        <th className="uk-text-right" scope="col">
          Actions
        </th>
      </tr>
    </thead>
  );
};

interface ITableRowProps {
  consortium: Consortium;
}
const TableRow = (props: ITableRowProps) => {
  const { id, name, members, tickets, createdOn } = props.consortium.asJson;

  // Initialize variables
  const navigate = useNavigate();
  const date = new Date(createdOn).toLocaleDateString();
  const displayDate = date === "Invalid Date" ? "-" : date;
  const totalPrice = tickets * 1500.0;

  // Open consortium
  const onOpen = () => navigate(id);

  return (
    <tr className="kit-table-row" data-aos="fade-up">
      <td scope="row" data-label="Name">
        {name}
      </td>
      <td scope="row" data-label="Identifier">
        {id}
      </td>
      <td data-label="Date">{displayDate}</td>
      <td className="uk-text-center" data-label="Members">
        {members.length}
      </td>
      <td className="uk-text-center" data-label="Tickets">
        {tickets}
      </td>
      <td className="uk-text-right" data-label="Tickets (N$)">
        N${round(totalPrice)}
      </td>
      <td className="uk-text-right" data-label="Actions">
        <button className="kit-btn kit-btn-text" onClick={onOpen}>
          Open
        </button>
      </td>
    </tr>
  );
};

interface ITableProps {
  consortiums: Consortium[];
}
const Table = (props: ITableProps) => {
  const { consortiums } = props;

  if (consortiums.length === 0)
    return (
      <NoData>
        No consortium found on the system! <br /> Create a consortium to start
        buying tickets in a group.
      </NoData>
    );

  return (
    <div className="table-container">
      <table className="kit-table">
        <TableHeader />
        <tbody>
          {consortiums.map((c) => (
            <TableRow key={c.asJson.id} consortium={c} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ConsortiumPage = observer(() => {
  const { api, store } = useAppContext();

  const navigate = useNavigate();
  const me = store.auth.meJson;

  // List all consortiums.
  const consortiums = useMemo(
    () => store.consortium.all.sort(sortConsortium),
    [store.consortium.all]
  );

  const [loading, setLoading] = useState(false);

  // consortium program
  const onCreateConsortium = () => showModal(MODAL_NAMES.CONSORTIUM_MODAL);

  // Buy tickets
  // const onBuy = () => {
  //   if (!me)
  //     swal({
  //       title: "Sign up!",
  //       text: "Create an account to start buying tickets!",
  //       icon: "error",
  //       timer: 2000,
  //     }).then(() => navigate("/sign-up"));
  //   else navigate("/portal/checkout");
  // };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (me) await api.consortium.getAll(me.uid);
      setLoading(false);
    };
    load();
  }, [api.consortium, me]);

  if (loading) return <Loading fullHeight isEllipsis />;

  // TODO: Accept terms and conditions before seeing a consortium page.
  // TODO: Only show a table none editable of consortiums and terms & conditions.

  return (
    <ErrorBoundary>
      <div className="affiliates-page">
        <div className="uk-section uk-section-small uk-section-muted">
          <div className="uk-container uk-container-large">
            <Header>
              <div>
                <DashboardCard type="positive">
                  <div className="content">
                    <div className="top">
                      <h3 className="digit">{consortiums.length}</h3>
                    </div>
                    <h3 className="title">Consortium</h3>
                    <p className="description">No. of all groups</p>
                  </div>
                </DashboardCard>
              </div>
            </Header>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-large">
            <div className="section-header" data-aos="fade-up">
              <h3 className="title">Consortium</h3>

              <div>
                <button
                  className="kit-btn kit-btn-success uk-margin-small-right"
                  onClick={onCreateConsortium}
                >
                  <FontAwesomeIcon icon={faPlus} /> Create Consortium
                </button>
              </div>
            </div>

            <Table consortiums={consortiums} />
          </div>
        </div>
      </div>

      <Modal modalId={MODAL_NAMES.CONSORTIUM_MODAL}>
        <ConsortiumModal />
      </Modal>
    </ErrorBoundary>
  );
});

export default ConsortiumPage;
