import InvoicePDFGen from "../pdf/InvoicePDFGen";
import Model from "./Model";

export type IWithdrawalStatus = 'pending' | 'verified' | 'paid' | 'cancelled' | 'expired';

export const defaultWithdrawal: IWithdrawal = {
  id: "",
  uid: "",
  userName: "",
  cardHolder: "",
  bankAccountNumber: "",
  bankAccountType: "",
  bankSwiftCode: "",
  bankName: "",
  bankAddress: "",
  amount: "",
  verifiedBy: "",
  status: "pending",
  createdOn: 0,
  verifiedOn: 0
}

export interface IWithdrawal {
  id: string;
  uid: string;
  userName: string;
  cardHolder: string;
  bankAccountNumber: string;
  bankAccountType: string;
  bankSwiftCode: string;
  bankName: string;
  bankAddress: string;
  amount: string;
  verifiedBy: string; // uid
  status: IWithdrawalStatus;
  createdOn: number;
  verifiedOn: number;
}

export default class Withdrawal extends Model<IWithdrawal> {

  // Cancel withdrawal.
  async cancel(): Promise<void> {
    const _withdrawal: IWithdrawal = { ...this.item, status: "cancelled" };
    await this.api.withdraw.update(_withdrawal);
  }

  // Download invoice
  async invoice(): Promise<void> {
    return await InvoicePDFGen(this.item)
  }
}
