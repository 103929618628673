import {
  query,
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { db } from "../config/firebase-config";
import { ITransaction } from "../models/Transaction";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { Timestamp } from "firebase/firestore";

export default class TransactionApi {
  private path: string | undefined = "transactions";

  constructor(private api: AppApi, private store: AppStore) { }

  // path setter
  setPath(path: string) {
    this.path = path;
  }

  async getAll() {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    this.store.transaction.removeAll();

    // create the query
    const $query = query(collection(db, path)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: ITransaction[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ id: doc.id, ...doc.data() } as ITransaction);
          });

          this.store.transaction.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, id), (doc) => {
      if (!doc.exists) return;
      const item = { id: doc.id, ...doc.data() } as ITransaction;

      this.store.transaction.load([item]);
    });

    return unsubscribe;
  }

  // create transaction
  async create(item: ITransaction) {
    const path = this.path;
    if (!path) return;

    const itemRef = doc(db, path, item.id);
    // update transaction creation date.
    item.createdOn = Timestamp.now().toMillis();

    // create in db
    try {
      await setDoc(itemRef, item, {
        merge: true,
      });
      // create in store
      this.store.transaction.load([item]);
    } catch (error) {
      console.log(error);
    }
  }

  // update item
  async update(item: ITransaction) {
    const path = this.path;
    if (!path) return;

    // update in db.
    try {
      await updateDoc(doc(db, path, item.id), {
        ...item,
      });
      // update in store
      this.store.transaction.load([item]);
    } catch (error) {
      console.log(error);
    }
  }

  // delete booking
  async delete(item: ITransaction) {
    const path = this.path;
    if (!path) return;

    try {
      // remove from db
      await deleteDoc(doc(db, path, item.id));
      // Remove from memory
      this.store.transaction.remove(item.id);
    } catch (error) {
      console.log(error);
    }
  }
}
