import {
  faAngleDown,
  faEllipsisVertical,
  faFileExcel,
  faHistory,
  faMoneyBillTransfer,
  faShareAlt,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import AffiliateModal from "../modals/affiliate-modal/AffiliateModal";
import ErrorBoundary from "../../common/components/error-boundary/ErrorBoundary";
import Loading from "../../common/components/loading/Loading";
import Modal, { showModal } from "../../common/components/modal/Modal";
import NoData from "../../common/components/no-data/NoData";
import { MODAL_NAMES } from "../../common/config/constants";
import { useAppContext } from "../../common/hooks/useContext";
import User from "../../common/models/User";
import { sortIUser, sortUser } from "../../common/utils/Sort";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { round } from "../../common/utils/Math";
import ApplyForWithdraw from "../modals/apply-for-withdraw/ApplyForWithdraw";
import WithdrawHistoryModal from "../modals/withdraw-history/WithdrawHistory";
import { utils as XLSXUtils, writeFileXLSX } from "xlsx";
import "./Style.scss";
import Dropdown from "../../common/components/dropdown/Dropdown";

interface IBuyTicketCardProps {
  onBuy: () => void;
}
const BuyTicketCard = (props: IBuyTicketCardProps) => {
  const { onBuy } = props;

  return (
    <div
      className="buy-ticket-card kit-card kit-card-sm kit-card-default"
      data-aos="fade-up"
    >
      <div className="ticket--img-container">
        <img src={process.env.PUBLIC_URL + "/assets/tickets/c.png"} alt="" />
      </div>
      <div className="footer">
        <button onClick={onBuy}>
          <FontAwesomeIcon icon={faTicket} /> Buy Tickets
        </button>
      </div>
    </div>
  );
};

interface IDashboardCardProps {
  type?: "positive" | "neutral";
  children: ReactNode;
  noIcon?: boolean;
}
const DashboardCard = (props: IDashboardCardProps) => {
  const { type = "neutral", children, noIcon } = props;

  const icon =
    type === "positive"
      ? process.env.PUBLIC_URL + "/icons/1332082.png"
      : process.env.PUBLIC_URL + "/icons/5044045.png";

  return (
    <div
      className={`dashboard-card dashboard-card--${type} kit-card kit-card-default`}
      data-aos="fade-up"
    >
      {children}
      {!noIcon && (
        <div className="icon">
          <img src={icon} alt="" />
        </div>
      )}
    </div>
  );
};

interface IRealtimeDashboardCardProps {
  type?: "positive" | "neutral";
  header: ReactNode;
  caption: ReactNode;
  valueType: "balance" | "commission";
  noIcon?: boolean;
}
const RealtimeDashboardCard = observer((props: IRealtimeDashboardCardProps) => {
  const { type = "neutral", header, caption, noIcon, valueType } = props;

  const { store } = useAppContext();
  const me = store.auth.meSnaptshotJson;

  const commission = (me && (me.commission as number)) || 0;
  const pendingWithdrawBalance = me?.pendingWithdrawBalance || 0;
  const balance = commission - pendingWithdrawBalance;

  return (
    <DashboardCard type={type} noIcon={noIcon}>
      <div className="content">
        {header}
        {valueType === "commission" && (
          <h3 className="title">N${round(commission / 100)}</h3>
        )}
        {valueType === "balance" && (
          <h3 className="title">N${round(balance / 100)}</h3>
        )}
        {caption}
      </div>
    </DashboardCard>
  );
});

interface IHeaderProps {
  onBuy: () => void;
  children: ReactNode;
}
const Header = (props: IHeaderProps) => {
  const { children, onBuy } = props;
  return (
    <div className="kit-header">
      <div
        className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
        data-uk-grid
      >
        {children}
        <div>
          <BuyTicketCard onBuy={onBuy} />
        </div>
      </div>
    </div>
  );
};

const TableHeader = () => {
  return (
    <thead>
      <tr className="kit-table-header" data-aos="fade-up">
        <th scope="col">Full name</th>
        <th scope="col">Date</th>
        <th className="uk-text-center" scope="col">
          Tickets
        </th>
        <th className="uk-text-right" scope="col">
          Tickets (N$)
        </th>
        {/* <th className="uk-text-right" scope="col">
          Commission Rate (%)
        </th> */}
        {/* <th className="uk-text-right" scope="col">
          Commission (N$)
        </th> */}
      </tr>
    </thead>
  );
};

interface ITableRowProps {
  affiliate: User;
}
const TableRow = (props: ITableRowProps) => {
  const { fullName, tickets, createdOn } = props.affiliate.asJson;
  const date = new Date(createdOn).toLocaleDateString();
  const displayDate = date === "Invalid Date" ? "-" : date;

  const totalPrice = tickets * 1500.0;
  // const commission = totalPrice * 0.05;

  const round = (amount: number) => (Math.round(amount * 100) / 100).toFixed(2);

  return (
    <tr className="kit-table-row" data-aos="fade-up">
      <td data-label="Full name">{fullName}</td>
      <td data-label="Date">{displayDate}</td>
      <td className="uk-text-center" data-label="Tickets">
        {tickets}
      </td>
      <td className="uk-text-right" data-label="Tickets (N$)">
        N${round(totalPrice)}
      </td>
      {/* <td className="uk-text-right" data-label="Commission Rate (%)">
        5.0%
      </td> */}
      {/* <td className="uk-text-right" data-label="Commission (N$)">
        N${round(commission)}
      </td> */}
    </tr>
  );
};

interface ITableProps {
  affiliates: User[];
}
const Table = (props: ITableProps) => {
  const { affiliates } = props;

  if (affiliates.length === 0)
    return (
      <NoData>
        No affiliates found on the system! <br /> Refer a friend to gain points
        when someone uses your link
      </NoData>
    );

  return (
    <div className="table-container">
      <table className="kit-table">
        <TableHeader />
        <tbody>
          {affiliates.map((affiliate) => (
            <TableRow key={affiliate.asJson.uid} affiliate={affiliate} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AffiliatesPage = observer(() => {
  const { api, store } = useAppContext();

  const navigate = useNavigate();
  const me = store.auth.meJson;

  // List all affiliate users.
  const affiliates = useMemo(
    () => store.affiliate.all.sort(sortUser),
    [store.affiliate.all]
  );

  const [loading, setLoading] = useState(false);
  const [loadingExportExcel, setLoadingExportExcel] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);

  // Refer/ Affiliate program
  const onRefer = () => showModal(MODAL_NAMES.AFFILIATE_MODAL);

  // Export affiliates to excel
  const onExportExcel = async () => {
    setLoadingExportExcel(true);
    if (!me) throw new Error("User not logged in");

    try {
      const $affiliates = (await api.affiliate.getAllDocs(me.uid)).sort(
        sortIUser
      );
      // Track the for loop index
      let index = 0;
      const rows: any[] = [];
      for await (const af of $affiliates) {
        rows.push({
          "First Name": af.firstName,
          "Last Name": af.lastName,
          "Email Address": af.email,
          "Tickets Purchased": af.tickets,
        });

        // Increment index
        index++;
        setExportProgress(Math.floor((index / affiliates.length) * 100));
      }

      const worksheet = XLSXUtils.json_to_sheet(rows);
      const workbook = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(workbook, worksheet, "Affiliates");
      writeFileXLSX(workbook, "raffle-affiliates.xlsx");
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingExportExcel(false);
      setExportProgress(0);
    }
  };

  // Withdraw
  const onWithdraw = () => showModal(MODAL_NAMES.WITHDRAW_MODAL);

  // Withdraw history
  const onWithdrawHistory = () => showModal(MODAL_NAMES.WITHDRAW_HISTORY_MODAL);

  // Buy tickets
  const onBuy = () => {
    if (!me)
      swal({
        title: "Sign up!",
        text: "Create an account to start buying tickets!",
        icon: "error",
        timer: 2000,
      }).then(() => navigate("/sign-up"));
    else navigate("/portal/checkout");
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (me) {
        await api.affiliate.getAllByUser(me.uid);
        await api.withdraw.getAllByUser(me.uid);
      }
      setLoading(false);
    };
    load();
  }, [api.affiliate, api.withdraw, me]);

  if (!me || loading) return <Loading fullHeight isEllipsis />;

  return (
    <ErrorBoundary>
      <div className="affiliates-page">
        <div className="uk-section uk-section-small uk-section-muted">
          <div className="uk-container uk-container-large">
            <Header onBuy={onBuy}>
              <div>
                <DashboardCard type="positive">
                  <div className="content">
                    <div className="top">
                      <h3 className="digit">{affiliates.length}</h3>
                    </div>
                    <h3 className="title">Referrals</h3>
                    <p className="description">All users from referrals.</p>
                  </div>
                </DashboardCard>
              </div>
              <div>
                <RealtimeDashboardCard
                  type="positive"
                  valueType="commission"
                  noIcon
                  header={
                    <div className="top top-img-container">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/5044045.png"}
                        alt=""
                      />
                    </div>
                  }
                  caption={
                    <p className="description">Total commission earned.</p>
                  }
                />
              </div>
              <div>
                <RealtimeDashboardCard
                  type="positive"
                  valueType="balance"
                  noIcon
                  header={
                    <div className="top top-img-container">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/5044045.png"}
                        alt=""
                      />
                    </div>
                  }
                  caption={<p className="description">Current Balance.</p>}
                />
              </div>
            </Header>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container uk-container-large">
            <div className="section-header" data-aos="fade-up">
              <h3 className="title">Affiliates</h3>

              <div>
                <button className="kit-btn kit-btn-success" title="More">
                  Actions
                  <FontAwesomeIcon
                    className="uk-margin-small-left"
                    icon={faAngleDown}
                  />
                </button>
                <Dropdown pos="bottom-right">
                  <li className="kit-dropdown-item">
                    <button className="kit-dropdown-btn" onClick={onRefer}>
                      <FontAwesomeIcon
                        className="uk-margin-small-right"
                        icon={faShareAlt}
                      />
                      Refer a friend
                    </button>
                  </li>
                  <li className="kit-dropdown-item">
                    <button
                      onClick={onExportExcel}
                      className="kit-dropdown-btn"
                      disabled={loadingExportExcel}
                    >
                      <FontAwesomeIcon
                        className="uk-margin-small-right"
                        icon={faFileExcel}
                      />
                      {loadingExportExcel
                        ? `Exporting - (${exportProgress}%)`
                        : "Export Excel"}
                    </button>
                  </li>
                  <li className="kit-dropdown-item">
                    <button
                      className="kit-dropdown-btn"
                      onClick={onWithdrawHistory}
                    >
                      <FontAwesomeIcon
                        className="uk-margin-small-right"
                        icon={faHistory}
                      />
                      Withdraw History
                    </button>
                  </li>
                  <li className="kit-dropdown-item">
                    <button className="kit-dropdown-btn" onClick={onWithdraw}>
                      <FontAwesomeIcon
                        className="uk-margin-small-right"
                        icon={faMoneyBillTransfer}
                      />
                      Apply for Withdraw
                    </button>
                  </li>
                </Dropdown>
              </div>
            </div>

            <Table affiliates={affiliates} />
          </div>
        </div>
      </div>

      <Modal modalId={MODAL_NAMES.AFFILIATE_MODAL}>
        <AffiliateModal />
      </Modal>
      <Modal modalId={MODAL_NAMES.WITHDRAW_MODAL}>
        <ApplyForWithdraw />
      </Modal>
      <Modal modalId={MODAL_NAMES.WITHDRAW_HISTORY_MODAL}>
        <WithdrawHistoryModal />
      </Modal>
    </ErrorBoundary>
  );
});

export default AffiliatesPage;
