import Model from "./Model";

export const defaultAd: IAdvert = {
  id: "",
  imageUrl: "",
  link: "",
  isActive: false,
  createdOn: Date.now()
};


export interface IAdvert {
  id: string;
  imageUrl: string;
  link: string;
  isActive: boolean;
  createdOn: number;
}


export default class Advert extends Model<IAdvert> {

}
