import {
  query,
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  Unsubscribe,
} from "@firebase/firestore";
import { db } from "../config/firebase-config";
import { IBlog, defaultBlog } from "../models/Blog";
import AppStore from "../stores/AppStore";
import { getDocs, limit, orderBy, startAfter } from "firebase/firestore";
import AppApi from "./AppApi";

export default class BlogApi {
  private path: string = "blogs";
  private lastVisible: any | null = null;

  constructor(private api: AppApi, private store: AppStore) { }

  // path setter
  setPath(path: string) {
    this.path = path;
  }

  async getAll() {
    // get the db path
    const path = this.path;
    if (!path) return;

    // remove all items from store
    // this.store.blog.removeAll();

    // create the query
    const $query = query(collection(db, path)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IBlog[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ id: doc.id, ...doc.data() } as IBlog);
          });

          this.store.blog.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }



  async getAllWithPaginate(lim = 15) {
    // get the db path
    const path = this.path;
    if (!path) return;

    // get orderby prop
    const orderByProp: keyof IBlog = "createdOn"

    // creat the a query
    const $query = this.lastVisible ?
      query(collection(db, path), orderBy(orderByProp, "desc"), startAfter(this.lastVisible), limit(lim)) :
      query(collection(db, path), orderBy(orderByProp, "desc"), limit(lim)); // query

    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IBlog[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ ...defaultBlog, id: doc.id, ...doc.data() } as IBlog);
          });

          this.store.blog.load(items);
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject(error);
        }
      );
    });
  }



  async getById(id: string) {
    const path = this.path;
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, id), (doc) => {
      if (!doc.exists) return;
      const item = { id: doc.id, ...doc.data() } as IBlog;

      this.store.blog.load([item]);
    });

    return unsubscribe;
  }


}


