import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import swal from "sweetalert";
import { IConsortiumMember } from "../../../common/models/ConsortiumMember";

interface IConsortiumMemberAccountProps {
  member: IConsortiumMember;
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  fixed?: boolean;
}
export const ConsortiumMemberAccount = (
  props: IConsortiumMemberAccountProps
) => {
  // extract props
  const { member, members, setMembers, fixed = false } = props;

  const onRemoveMember = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, member can no longer has access to consortium!",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const _newMembers = members.filter((m) => m.email !== member.email);

        if (_newMembers.length >= 2) {
          setMembers(_newMembers);
          swal("Poof! Consortium member has been deleted!", {
            icon: "success",
          });
        } else {
          swal({
            title: "Error!",
            text: "You cannot remove members from consortium. At least 2 members are required!",
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        swal({
          text: "Consortium member is safe!",
          timer: 1000,
        });
      }
    });
  };

  return (
    <div className="uk-margin-small consortium-member-account">
      <p className="consortium-member-account-name">{member.fullName}</p>
      {!fixed && (
        <button
          className="delete-member"
          type="button"
          onClick={onRemoveMember}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}
    </div>
  );
};

interface IConsortiumMemberEmailProps {
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  findUserByEmail: (email: string) => Promise<IConsortiumMember[] | undefined>;
}
const ConsortiumMemberEmail = (props: IConsortiumMemberEmailProps) => {
  // Extract props;
  const { members, setMembers, findUserByEmail } = props;

  // Initialize component states
  const [email, setEmail] = useState("");

  // Find member form db.
  const searchMemberByEmail = async () => {
    const _members = await findUserByEmail(email);
    // If member found.
    if (_members) addMemberToConsortium(_members);
    // Else notify user, invalid account.
  };

  // Add member to consortium if exists in db.
  const addMemberToConsortium = (_members: IConsortiumMember[]) => {
    // Check members length
    if (members.length >= 5) {
      onAlert(
        "Error!",
        "You've reaved the maximum number of allowed members (Max. of 5 members per Consortium)",
        "error",
        3000
      );
      return;
    }
    // Check if member already added to list.
    const memberIndex = members.findIndex((m) => m.email === email);

    if (memberIndex === -1) {
      setMembers([...members, ..._members]);
      setEmail("");
    } else {
      onAlert("Error!", "Member already added to Consortium", "error");
    }
  };

  const onAlert = (
    title: string,
    msg: string,
    icon: string = "success",
    timer: number = 2000
  ) => {
    swal({
      title: title,
      text: msg,
      icon: icon,
      timer: timer,
    });
  };

  return (
    <div className="uk-margin consortium-member-email">
      <label className="uk-form-label" htmlFor="cstium-email">
        Enter Member's Email Address
      </label>
      <div className="uk-form-controls consortium-member-email-form-controls">
        <input
          className="uk-input"
          id="cstium-email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button
          className="validate-member"
          type="button"
          onClick={searchMemberByEmail}
        >
          ADD <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
};

interface IConsortiumStep2MembersProps {
  members: IConsortiumMember[];
  setMembers: Dispatch<SetStateAction<IConsortiumMember[]>>;
  findUserByEmail: (email: string) => Promise<IConsortiumMember[] | undefined>;
  children: ReactNode;
}
const ConsortiumMembers = (props: IConsortiumStep2MembersProps) => {
  // extract props
  const { members, setMembers, findUserByEmail, children } = props;

  return (
    <>
      {children}

      {/* Hide if the number of members are enough/reached max */}
      {members.length < 5 && (
        <ConsortiumMemberEmail
          members={members}
          setMembers={setMembers}
          findUserByEmail={findUserByEmail}
        />
      )}
    </>
  );
};

export default ConsortiumMembers;
